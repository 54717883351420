<template id="tmplSpecialInst">
  <section id="secSpecialInst" class="container">
    <b-row id="rowSpecialInstHdr">
      <h4 id="hdrSpecialInst" class="text-primary">Special Instructions & Notes</h4>
    </b-row>
    <b-row id="rowPoSalesNumber">
      <b-col id="colPOSalesNumber" lg="4" md="4" sm="12" class="pd-10">
        <b-form-group id="txtGrpPOSalesOrder" label="PO/Sales Order #" label-for="txtPoSalesNumber">
          <b-form-input id="txtPoSalesNumber" v-model="poNumber"/>
        </b-form-group>
      </b-col>
			<b-col id="colThirdPartyPO" lg="4" md="4" sm="12" class="pd-10">
				<b-form-group id="txtGrpThirdPartyPO" label="Third Party PO #" label-for="txt3Po">
					<b-form-input id="txt3PoNumber" v-model="thirdPartyPONumber"/>
				</b-form-group>
			</b-col>
    </b-row>
    <b-row>
      <b-col id="colAdditionalNotes" lg="8" md="8" sm="12">
        <b-form-group id="txtGrpAdditionalNotes" label="Additional Notes/Remarks" label-for="txtNotes">
          <b-form-textarea id="txtNotes" v-model="notes" rows="4" max-rows="6"/>
        </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  export default {
  name: "SpecialInstructions.vue",
  computed: {
    poNumber: {
      get() {
        return this.$store.state.newOrderTender.poNumber;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setPoNumber", value);
      }
    },
    notes: {
      get() {
        return this.$store.state.newOrderTender.notes;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setNotes", value);
      }
    },
		thirdPartyPONumber: {
			get(){
				return this.$store.state.newOrderTender.thirdPartyPO;
			},
			set(value) {
        this.$store.dispatch("newOrderTender/set3PoNumber", value);
      }
		}
  }
};
</script>
