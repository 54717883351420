<template id="tmplProductDetails">
  <section class="container">
    <b-row id="rowProductDetailsHeader">
      <h4 id="hdrProductDetails" class="text-primary">Product Details</h4>
    </b-row>
    <b-row id="rowShowProductSlideIn">
      <b-button id="btnShowProductSlideIn"
        variant="link"
        @click="showProductSlideIn()"
        size="lg"
        :disabled="consignees.length == 0"
      >
        <i class="fa fa-plus fa-md mr-2"></i>Add Product
      </b-button>
    </b-row>
    <b-row v-if="selectedProducts.length">
      <b-table id="tblProductDetails"
        ref="productsTable"
        :fields="tableDefaultFields"
        :items.sync="selectedProducts"
        :show-empty="true"
        :empty-text="table.emptyText"
        :responsive="true"
        :filter="null"
        :small="true"
      >
      <template id="tmplDeliverySite" v-slot:cell(deliverySite)="row">
          <div id="divDeliverySite"
            class="text-wrap text-break"
            style="width:200px;"
          >{{ getDeliveryAddress(row.value, 'N/A') }}</div>
        </template>
        <template id="tmplShipper" v-slot:cell(shipper)="row">
          <div id="divShpper"
            style="width:150px;"
          >{{ row.value.id && row.value.id.trim() != "CALLDIS" ? getDeliveryAddress(row.value, 'N/A') : 'N/A'}}</div>
        </template>
        <template id="tmplSupplier" v-slot:cell(supplier)="row">
          <div id="divSupplier"
            style="width:150px;"
          >{{ row.value.id && row.value.id.trim() != 'CALLDIS' ? getDeliveryAddress(row.value, 'N/A') : 'N/A' | displayValueBasicModeNA(row.item.isAdvancedView)}}</div>
        </template>
        <template id="tmplAccountOf" v-slot:cell(accountOf)="row" style="width:150px;">{{ row.value.name | displayValueBasicModeNA(row.item.isAdvancedView) }}</template>
        <template id="tmplProduct" v-slot:cell(product)="row">
          <div id="divProduct" style="width:200px;">{{ row.value ? row.value.name : 'N/A'}}</div>
        </template>

        <template id="tmplLoadWindow" v-slot:cell(loadWindow)="row">
          <div id="divLoadWindow" style="width:150px;">{{ row.value ? getDateIntervalFormatted(row.value) : 'N/A'}}</div>
        </template>
        
        <template id="tmplDeliveryWindow" v-slot:cell(deliveryWindow)="row">
          <div id="divDeliveryWindow" style="width:150px;">{{ row.value ? getDateIntervalFormatted(row.value) : 'N/A'}}</div>
        </template>

        <template id="tmplAmount" v-slot:cell(amount)="row">{{ buildQuantityString(row) }}</template>
        <template id="tmplActions" v-slot:cell(actions)="row">
          <i id="itlEditProduct" class="fa fa-edit fa-lg text-info icon" @click="editProduct(row.index)"></i>
          <i id="itlDeleteProduct" class="fa fa-trash ml-2 fa-lg text-danger icon" @click="deleteProduct(row.index)"></i>
        </template>
        <template id="tmplEmpty" slot="empty" slot-scope="scope">
          <h5 id="hdrEmptyText" class="text-center">{{ scope.emptyText }}</h5>
        </template>
      </b-table>
    </b-row>
    <b-row id="rowNoProducts" v-else class="justify-content-center">
      <b-alert id="alrtNoProducts" class="center-block" show variant="light">You have no products.  Please click the 'Add Product' button above to add some.</b-alert>
    </b-row>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash"
import { required, between } from "vuelidate/lib/validators";
import "vue-select/src/scss/vue-select.scss";
import vSelect from "vue-select";
import ProductSlideIn from "@/components/orders/ProductSlideIn";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import mixin from "@/shared/mixin";
import { divisions, minCapacity, maxCapacity } from "@/shared/constants"

import { dateToClientReadable } from "@/shared/utils/date-formatter"

export default {
  name: 'ProductDetails.vue',
  components: {
    vSelect
  },
  props: [],
  data() {
    return {
      minCapacity,
      maxCapacity,
      spgTable: {
          fields: [
            "deliverySite",
            "shipper",
            "product",
            { "loadWindow": "Initial Load Window" },
          { "deliveryWindow": "Scheduled Delivery Window" },
            "amount",
            "actions"
          ],
          emptyText:
            "You have no products.  Please click the 'Add Product' button above to add some."
        },
      table: {
        fields: [
          "deliverySite",
          "shipper",
          "supplier",
          "accountOf",
          "product",
          { "loadWindow": "Initial Load Window" },
          { "deliveryWindow": "Scheduled Delivery Window" },
          "amount",
          "actions"
        ],
        emptyText:
          "You have no products.  Please click the 'Add Product' button above to add some."
      }
    };
  },
  mounted() {
    //can check state objects in here.
    if (this.tenderForConsigneeId) {
      this.showProductSlideIn();
    }

    this.$root.$on("toggleEditIndex", val => {
      this.editIndex = val;
    });

    this.$root.$on("refreshProductsTable", () => {
      if (this.$refs.productsTable) this.$refs.productsTable.refresh();
    });
  },
  mixins: [mixin],
  methods: {
    async editProduct(index) {
      this.$root.$emit("isEditLoading", true);
      this.$store.dispatch("newOrderTender/setIsEditMode", true);
      try {
        var product = this.selectedProducts[index];
        this.$store.dispatch(
          "newOrderTender/setSelectedConsignee",
          product.deliverySite
        );

        if (product.shipper && product.shipper.id != "CALLDIS") {
          this.$store.dispatch(
            "newOrderTender/setSelectedShipper",
            product.shipper
          );
        }

        if (product.supplier != null && product.supplier.id != "CALLDIS") {
          this.$store.dispatch(
            "newOrderTender/setSelectedSupplier",
            product.supplier
          );
        }

        if (product.accountOf != null ? product.accountOf.id : "") {
          this.$store.dispatch(
            "newOrderTender/setSelectedAccountOf",
            product.accountOf
          );
        }

        if (product.product != null ? product.product.id : "") {
          this.$store.dispatch(
            "newOrderTender/setSelectedCommodity",
            product.product
          );
        }

        this.$store.dispatch(
          "newOrderTender/setSelectedQuantity",
          product.amount
        );

        var aum=(product.amountUnitOfMeasure != '' ? product.amountUnitOfMeasure : this.selectedAmountUnitOfMeasure);
        if(aum==''){
          aum="GAL";
        }
        this.$store.dispatch(
          "newOrderTender/setSelectedAmountUnitOfMeasure",
          aum
        );


        //Get Data for dropdowns -- todo: this should be built into one service call since they are tied in a chain
        var shippers, suppliers, accountOfs, commodities;
        shippers = this.$store.dispatch("newOrderTender/getShippers", {
          jwtToken: this.$store.state.user.jwtToken,
          billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
          consignee: this.selectedConsignee.id
        });

        if (this.selectedShipper) {
          suppliers = this.$store.dispatch("newOrderTender/getSuppliers", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id,
            shipper: this.selectedShipper.id
          });
        }

        if (this.selectedSupplier) {
          accountOfs = this.$store.dispatch("newOrderTender/getAccountOfs", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id,
            shipper: this.selectedShipper.id,
            supplier: this.selectedSupplier.id
          });
        }

        commodities = this.$store.dispatch("newOrderTender/getCommodities", {
          jwtToken: this.$store.state.user.jwtToken,
          billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
          consignee: this.selectedConsignee.id
        });

        // have to wait them one by one in sequence -- can not use await Promise.all([])
        [shippers, suppliers, accountOfs, commodities].reduce(
          (p, task) => p.then(task),
          Promise.resolve()
        );

        var mode;
        if (this.createdDate) {
          mode = "Edit";
        }

        const panelInstance = this.$showPanel({
          component: ProductSlideIn,
          props: { editIndex: index, Mode: mode, Product: product },
					openOn: 'right'
        });

        panelInstance.promise.then(() => {
          this.$store.dispatch("newOrderTender/setSelectedConsignee", "");
          this.$store.dispatch("newOrderTender/clearProductDetails");
        });
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
      this.$root.$emit("isEditLoading", false);
    },
    deleteProduct(index) {
      this.$store.dispatch("newOrderTender/deleteProduct", index);
    },
    onModalClosed() {
      this.$store.dispatch("newOrderTender/clearProductDetails");
      this.$v.$reset();
      this.editIndex = null;
      this.showModal = false;
    },
    async onShipperChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setSelectedSupplier", "");
        this.$store.dispatch("newOrderTender/setSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setSelectedShipper", value);

        if (this.selectedShipper) {

          await this.$store.dispatch(
            "newOrderTender/getSuppliers",
            {
              jwtToken: this.$store.state.user.jwtToken,
              billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedShipper.id
            }
          );
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },
    async onSupplierChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setSelectedSupplier", value);

        if (this.selectedSupplier) {

          await this.$store.dispatch(
            "newOrderTender/getAccountOfs",
            {
              jwtToken: this.$store.state.user.jwtToken,
              billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedShipper.id,
              supplier: this.selectedSupplier.id
            }
          );
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },
    onAccountOfChange(value) {
      this.$store.dispatch("newOrderTender/setSelectedCommodity", "");
      this.$store.dispatch("newOrderTender/setSelectedAccountOf", value);
    },
    onCommodityChange(value) {
      this.$store.dispatch("newOrderTender/setSelectedCommodity", value);
    },
    onQuantityChange(value) {
      this.$store.dispatch("newOrderTender/setSelectedQuantity", value);
    },
    chkState(val) {
      const field = this.$v[val];
      return !field.$dirty || !field.$invalid;
    },
    showProductSlideIn() {
      const panelInstance = this.$showPanel({
        component: ProductSlideIn,
        props: { tenderForConsigneeId: this.tenderForConsigneeId },
				openOn: 'right'
      });

      panelInstance.promise.then(() => {
        this.$store.dispatch("newOrderTender/setSelectedConsignee", "");
        this.$store.dispatch("newOrderTender/clearProductDetails");
      });
    },
    buildQuantityString(row) {
      if (row.item.callForQuantity) {
        return "Call For Quantity";
      }
      return row.value + ' ' + row.item.amountUnitOfMeasure;
    },
    getDateIntervalFormatted(intervals) {
      var dateIntervals = intervals.split('/')
      return `${dateToClientReadable(dateIntervals[0])} ${dateToClientReadable(dateIntervals[1])}`
    }
  },
  computed: {
    ...mapState("newOrderTender", [
      "commodities",
      "consignees",
      "shippers",
      "suppliers",
      "accountOfs",
      "selectedConsignee",
      "selectedProducts",
      "selectedShipper",
      "selectedSupplier",
      "selectedAccountOf",
      "selectedCommodity",
      "selectedSplashCommodity",
      "selectedQuantity",
      "createdDate",
      "selectedAmountUnitOfMeasure",
      "amountUnitOfMeasure"
    ]),
    ...mapGetters("companyMultiSelects", ["getIsSpg"]),
    tenderForConsigneeId() {
      return this.$route.query.consigneeId;
    },
    tableDefaultFields() {

    const mapEmitter = new Map([
      [
        divisions.SPG, () => this.spgTable.fields
      ],
      [
        divisions.FDG, () => {

          if(this.selectedProducts.some(e => e.isAdvancedView)){
            return  this.table.fields;
          }else{
            return _.difference(this.table.fields,
            [
              "supplier",
              "accountOf"
            ])

          }
        }
      ]
    ])

    let product = this.selectedProducts[0];
    return mapEmitter.get(product.deliverySite.division == divisions.FDG ? divisions.FDG : divisions.SPG)()
    },
  },
  filters: {
    displayValueBasicModeNA(value, isAdvancedView){
      if(isAdvancedView){
        return value
      }else{
        return "N/A"
      }
    }
  },
  validations() {
    if (!this.isAdvancedView) {
      return {
        selectedConsignee: {
          required
        },
        selectedQuantity: {
          required,
          between: between(this.minCapacity.FDG, this.maxCapacity.FDG)
        },
        selectedCommodity: {
          required
        }
      };
    } else {
      if(this.selectedProducts[0].deliverySite.division == divisions.SPG){
        return {
          selectedConsignee: {
            required,
          },
          selectedQuantity: {
            required
          },
          selectedShipper: {
            required,
          }
        };
      }else{
        return {
        selectedConsignee: {
          required
        },
        selectedQuantity: {
          required,
          between: between(this.minCapacity.SPG, this.maxCapacity.SPG)
        },
        selectedCommodity: {
          required
        },
        selectedShipper: {
          required
        },
        selectedSupplier: {
          required
        },
        selectedAccountOf: {
          required
        }
      };
      }
    }
  }
};

</script>

<style scoped>
.icon {
  cursor: pointer;
}
</style>

