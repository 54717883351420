<template id="tmplDeliveryDetails">
  <section id="secDeliveryDetails" class="container">
    <b-row id="rowDelDetailsHdr">
      <h4 id="hdrDeliveryDetails" class="text-primary">Delivery Details</h4>
    </b-row>
    <b-row id="rowDelDetailsDelDate">
      <b-col id="colDelDetailsDelDate" lg="4" md="6" sm="12" class="pd-10">
        <b-form-group id="txtGrpDelDetailsDelDate">
          <label id="lblDelDetailsDelDate" for="txtDeliveryDate">Delivery Date
            <b-button id="btnDelDetailsDelDate" size="sm" class="resetBtn" v-b-tooltip.hover title="Reset Delivery Date/Time" variant="link"
            @click="resetDeliveryDate()" :disabled="selectedDeliveryTime === 'custom' ? true : false">
                <i id="itlDelDetailsDelDate" class="fa fa-xs fa-refresh" aria-hidden="true" ></i>
              </b-button>
          </label>
          <date-picker
               id="txtDeliveryDate"
               v-model="selectedDeliveryDate"
              :key='deliveryDateKey'
              :disabled="selectedDeliveryTime === 'custom' ? true : false"
              type="date"
              :mode= "Mode"
              :minDate="minDate"
              @onChange='deliveryDateChange'
          />
          <!-- <b-form-invalid-feedback
            :state="inputValidations.chkState('selectedDeliveryDate', ValidationModel)"
          >Delivery Date is required...</b-form-invalid-feedback> -->
        </b-form-group>
      </b-col>
      <b-col id="colDelDetailsDeliveryTime" lg="5" md="6" sm="12" class="pd-10">
        <b-form-group
          id="rbgDeliveryTime" :key='DeliveryTimeKey'
          label="Delivery Time"
          name="deliveryTimeRadioGroup"
          :class="inputValidations.chkState('selectedDeliveryTime', ValidationModel) ? '': 'text-danger'"
        >
          <b-form-radio
            id="rbMorning"
            value="morning"
            v-model="selectedDeliveryTime"
            name="deliveryTimeRadioGroup"
          >Morning (6:00 AM - 12:00 PM)</b-form-radio>
          <b-form-radio
            id="rbAfternoon"
            value="afternoon"
            v-model="selectedDeliveryTime"
            name="deliveryTimeRadioGroup"
          >Afternoon (12:00 PM - 6:00 PM)</b-form-radio>
          <b-form-radio
            id="rbEvening"
            value="evening"
            v-model="selectedDeliveryTime"
            name="deliveryTimeRadioGroup"
          >Evening (6:00 PM - 11:59 PM)</b-form-radio>
          <b-form-radio
            id="rbCustom"
            value="custom"
            v-model="selectedDeliveryTime"
            name="deliveryTimeRadioGroup"
          >Custom</b-form-radio>
          <!-- <b-form-invalid-feedback
            v-if="!ValidationModel.selectedDeliveryTime.required && ValidationModel.selectedDeliveryTime.$dirty"
          >Delivery Time required.</b-form-invalid-feedback> -->
        </b-form-group>
        <b-row id="rowDelDetailsFromDateTime" v-if="selectedDeliveryTime == 'custom'">
          <b-col id="colDelDetailsFromDate" lg="6" md="6" sm="12">
            <label id="lblDelDetailsFromDate">From Date
              <b-button id="btnDelDetailsResetFromDate" size="sm" class="resetBtn" v-b-tooltip.hover title="Reset From Date/Time" variant="link" @click="resetFromCustomTime()">
                <i id="itlDelDetailsResetFromDateBtn" class="fa fa-xs fa-refresh" aria-hidden="true" ></i>
              </b-button>
            </label>
            <date-picker
              id="txtCustomFromDate"
              v-model="selectedFromCustomDate"
              :key='fromCustomDateKey'
              type="date"
              :mode= "Mode"
              :minDate="minFromDate"
              @onChange='customFromDateChanged'
            />
            <!-- <b-form-invalid-feedback
              v-if="selectedDeliveryTime == 'custom'"
              :state="inputValidations.chkState('selectedFromCustomDate', ValidationModel)"
            >From Date required.</b-form-invalid-feedback> -->
          </b-col>
          <b-col id="colDelDetailsFromTime" lg="6" md="6" sm="12">
            <label id="lblDelDetailsFromTime">From Time
              <b-button id="btnDelDetailsResetFromTime" size="sm" class="resetBtn" v-b-tooltip.hover title="Reset From Date/Time" variant="link" @click="resetFromCustomTime()">
                <i id="itlDelDetailsResetFromTimeBtn" class="fa fa-xs fa-refresh" aria-hidden="true" ></i>
              </b-button>
            </label>
            <date-picker
              id="txtCustomFromtime"
              v-model="selectedFromCustomTime"
              :key='fromCustomTimeKey'
              type="time"
              :mode= "Mode"
              :minDate="minFromDate"
              @onChange='customFromTimeChanged'
            />
          </b-col>
        </b-row>
        <b-row id="rowDelDetailsToDateTime" class="mt-2" v-if="selectedDeliveryTime == 'custom'">
          <b-col id="colDelDetailsToDate">
            <label id="lblDelDetailsToDate">To Date
              <b-button id="btnDelDetailsResetToDate" size="sm" class="resetBtn" v-b-tooltip.hover title="Reset To Date/Time" variant="link" @click="resetToCustomTime()">
                <i id="itlDelDetailsResetToDateBtn" class="fa fa-xs fa-refresh" aria-hidden="true" ></i>
              </b-button>
            </label>
            <date-picker
              id="txtCustomToDate"
              v-model="selectedToCustomDate"
              :key='toCustomDateKey'
              type="date"
              :mode= "Mode"
              :minDate="minToDate"
              @onChange='customToDateChanged'
            />
            <!-- <b-form-invalid-feedback
              v-if="selectedDeliveryTime == 'custom'"
              :state="inputValidations.chkState('selectedToCustomDate', ValidationModel)"
            >To Date required.</b-form-invalid-feedback> -->
          </b-col>
          <b-col id="colDelDetailsToTime">
            <label id="lblDelDetailsToTime">To Time
              <b-button id="btnDelDetailsResetToTime" size="sm" class="resetBtn" v-b-tooltip.hover title="Reset To Date/Time" variant="link" @click="resetToCustomTime()">
                <i id="itlDelDetailsResetToTimeBtn" class="fa fa-xs fa-refresh" aria-hidden="true" ></i>
              </b-button>
            </label>
            <date-picker
              id="txtCustomToTime"
              v-model="selectedToCustomTime"
              :key='toCustomTimeKey'
              type="time"
              :mode= "Mode"
              :minDate="minToDate"
              @onChange='customToTimeChanged'
            />
            <!-- <b-form-invalid-feedback
              v-if="selectedDeliveryTime == 'custom'"
              :state="inputValidations.chkState('selectedToCustomTime', ValidationModel)"
            >To Time required.</b-form-invalid-feedback> -->
          </b-col>
        </b-row>
        <!-- <b-form-invalid-feedback
          :state="inputValidations.chkTimeState('selectedDeliveryTime', ValidationModel)"
        >Delivery Time must be 24 hours in the future.</b-form-invalid-feedback> -->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import inputValidations from "@/shared/utils/input-validation";

import DatePicker from "@/components/shared/DatePicker"
import moment from "moment";

import _ from "lodash";

import mixin from '@/shared/mixin';
import { dateTimeFormats } from '@/shared/constants'
import { canViewUsers } from "@/shared/utils/permissions";
import UserTemplateAssignmentMultiselect from  "@/components/multiselects/UserTemplateAssignmentMultiSelect"
import { deliveryWindows } from "@/shared/constants";

  export default {
  name: "DeliveryDetails.vue",
  props: ["ValidationModel", "Mode"],
  mixins: [mixin],
  data() {
    return {
      inputValidations: inputValidations,
      minDate: this.getMinFromDate(0),
      minFromDate: this.getMinFromDate(0),
      minToDate: this.getMinToDate(0),
      deliveryDateKey: Math.random(),
      DeliveryTimeKey: Math.random(),
      fromCustomDateKey: Math.random(),
      fromCustomTimeKey: Math.random(),
      toCustomDateKey: Math.random(),
      toCustomTimeKey: Math.random(),
      toCustomDateDisabled: true,
      isMultiUser: false,
      isAssignToAllUsers: false
    };
  },
  watch: {
    selectedDeliveryTime: function(value) {
      // init set up the custom from/to date/time if the store state is null
      if (value === 'custom' && !this.selectedFromCustomDate) {
        this.selectedFromCustomDate = moment(this.minFromDate).format(dateTimeFormats.outputDateFormat);
        this.selectedFromCustomTime = moment().format(dateTimeFormats.outputTimeFormat);
        this.minToDate = this.getMinToDate(0); // today
        this.selectedToCustomDate = moment(this.minToDate).format(dateTimeFormats.outputDateFormat);
        this.minToTime = this.getMinToDate(); // tomorrow this time
        this.selectedToCustomTime = moment(this.minToTime).format(dateTimeFormats.outputTimeFormat);
      }
    },

    minToDate: function() {
      // check the from/to 4 hour away, to is 24 from today, then decide to see whether to reset
      this.toCustomDateKey++;
      this.toCustomTimeKey++;
    }
  },
  components: {
    DatePicker,
    UserTemplateAssignmentMultiselect
  },
  mounted() {
    // for new order
    if (this.Mode.toLowerCase() === 'create') {
      this.minDate = this.getMinFromDate(1);
      this.deliveryDateKey++;
      this.selectedDeliveryTime = 'morning';
      this.selectedDeliveryDate = moment(this.minDate).format(dateTimeFormats.outputDateFormat);
      this.DeliveryTimeKey++;
    }
  },
  methods: {
    ...mapActions("newOrderTender", ["setSelectedDeliverySite"]),

    getOptionLabel(o) {
      return `${o.name} - ${o.city}, ${o.state}`;
    },
    deliveryDateChange(newValue) {
      if (!newValue) {
        this.deliveryDateKey++;
      }
    },

    customFromDateChanged(newValue, oldValue) {
      if (!newValue) {
        this.fromCustomDateKey++;
      } else {
        // first get the whole fromdate and from time

        var fromdatetime = `${newValue}T${this.selectedFromCustomTime}`;
        this.customFromDateTimeChanged(fromdatetime);
   
      }
    },

    customFromTimeChanged(newValue, oldValue) {
      if (!newValue) {
        this.fromCustomTimeKey++;
      }
      else {
        // first get the whole fromdate and from time
        var fromdatetime = `${this.selectedFromCustomDate}T${newValue}`;
        let momentSelectedFromCustomDate = moment(`${this.selectedFromCustomDate}T${this.selectedFromCustomTime}`);
        let momentSelectedToCustomDate = moment(`${this.selectedToCustomDate}T${this.selectedToCustomTime}`);

        if(momentSelectedToCustomDate.diff(momentSelectedFromCustomDate, 'hours') <= deliveryWindows.minWindowHours) this.customFromDateTimeChanged(fromdatetime);
      }
    },
    customFromDateTimeChanged(fromdatetime){
      // first get the whole fromdate and from time
      this.minToDate = this.getMinToDate(fromdatetime); // reset the min todate with default window

      this.selectedToCustomDate = moment(this.minToDate).format(dateTimeFormats.outputDateFormat);
      this.selectedToCustomTime = moment(this.minToDate).format(dateTimeFormats.outputTimeFormat);
      this.customToDateTimeChanged(fromdatetime)
   

    },

    customToDateChanged(newValue) {
      if (!newValue) {
        this.toCustomDateKey++;
        this.toCustomTimeKey++;
      }
      else {
      // first get the whole fromdate and from time

      var fromdatetime = `${newValue}T${this.selectedFromCustomTime}`;
      this.customToDateTimeChanged(fromdatetime);

    }
    },

    customToTimeChanged(newValue) {
      if (!newValue) {
        this.toCustomTimeKey++;
        return;
      }
      // no good way for historical reason since there are two separate pickers for date and time -- todo: combine into single datetime picker
      // need to reset the date -- this is time picker so the start date is always in JS start date 1970/1/1
      var selected = this.getDateFromOutput(this.selectedToCustomDate, newValue); // add the todate with totime and compare
      var diff = selected.diff(moment(), 'hours');
      if (diff <= 0) { // look like the clock rotated back
        this.selectedToCustomDate = moment(this.selectedToCustomDate).add(0, 'days').format(dateTimeFormats.outputDateFormat);
        //this.minToDate = this.getMinToDate();
        this.toCustomDateKey++;
      }
    },

    customToDateTimeChanged(fromdatetime) {
      // first get the whole fromdate and from time
      this.minToDate = this.getMinToDate(0); // reset the min todate with default window
      this.selectedToCustomDate = moment(fromdatetime).format(dateTimeFormats.outputDateFormat);
   //   this.selectedToCustomTime = moment(fromdatetime).format(dateTimeFormats.outputTimeFormat);
    },

    resetDeliveryDate() {
      this.selectedDeliveryDate = this.getMinFromDate(1);
      this.deliveryDateKey++;
      this.selectedDeliveryTime='morning';
    },

    checkDisabled(value) {
      return false;
    }
  },
  computed: {
    ...mapState("newOrderTender", ["consignees", "selectedConsignee"]),
    ...mapGetters("user", ["loggedInUser"]),
    selectedDeliveryDate: {
      get() {
        return this.$store.state.newOrderTender.selectedDeliveryDate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedDeliveryDate", value);
      }
    },
    selectedDeliveryTime: {
      get() {
        return this.$store.state.newOrderTender.selectedDeliveryTime;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedDeliveryTime", value);
      }
    },
    selectedFromCustomDate: {
      get() {
        return this.$store.state.newOrderTender.selectedFromCustomDate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedFromCustomDate", value);
      }
    },
    selectedFromCustomTime: {
      get() {
        return this.$store.state.newOrderTender.selectedFromCustomTime;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedFromCustomTime", value);
      }
    },
    selectedToCustomDate: {
      get() {
        return this.$store.state.newOrderTender.selectedToCustomDate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedToCustomDate", value);
      }
    },
    selectedToCustomTime: {
      get() {
        return this.$store.state.newOrderTender.selectedToCustomTime;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedToCustomTime", value);
      }
    },
    canViewUsers() {
      return canViewUsers(this.$store)
    }
    // checkDisabled: (app) => (value) => {
    //     return false;
    // }
  }
};
</script>


<style scoped>
.custom-is-invalid {
  border-radius: 5px;
  border-color: #c22032;
}

.city-state-text {
  font-size: 12px;
  color: black;
}
.resetBtn {
  padding: 0 0 0 0;
  width: 2rem;
}
</style>


