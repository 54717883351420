<template id="tempNewOrderProductSlideIn">
  <section id="secContainerOne" class="container grey-background slide">
    <div id="divParent" class="container vld-parent">
      <loading
        id="ldNewOrderProductSlideIn"
        :active.sync="isLoading"
        :is-full-page="true"
        color="#114C8E"
      ></loading>
      <b-row
        id="rowAddProductTitleRow"
        style="display: flex; justify-content: space-between"
      >
        <h1 id="hdrAddProductsTitle" class="text-primary mb-1 ml-4 mb-3">
          Add Products
        </h1>
        <b-button id="btnCloseSlideout" variant="link" @click="closeWindow()">
          <i class="fa fa-lg fa-window-close" aria-hidden="true"></i>
        </b-button>
      </b-row>

      <b-card id="crdCardShadowBox" class="card-box-shadow">
        <b-row id="rowOneProductSlideIn">
          <b-col id="colDeliverySite" class="pd-10" cols="10" md="7">
            <label id="lblDeliverySite">Delivery Site</label>
            <v-select
              label="name"
              id="ddlDeliverySite"
              @input="onDeliverySiteChanged"
              :value="$store.state.newOrderTender.selectedConsignee"
              :disabled="
                consignees.length == 0 ||
                Mode == 'Edit' ||
                matchTemplateDeliverySite
              "
              :options="filteredConsigneesWithKey"
              :class="
                chkState('selectedConsignee') == false ? 'invalid-input' : ''
              "
              placeholder="Select Delivery Site"
              :get-option-label="getDeliveryLabel"
            >
              <template id="tmplDeliverySiteOption"
                        slot="option"
                        slot-scope="option"
                        class="mt-4 mb-2">
                <div class="row mb-2">
                  <div class="col-sm">{{ option.name }}</div>
                  <div class="col-sm">{{ option.billTo }}</div>
                </div>
                <div id="divDelvieryStieOptionCityStateText" class="row city-state-text mb-2">
                      <span class="col-sm">
                        {{ option.address }}
                      </span>
                      <span class="col-sm" v-if="option.address2 && option.address2.length > 0">
                        {{ option.address2 }}
                      </span>
                      <span class="col-sm">
                        {{ option.city }} {{ option.state }} {{ option.zipCode }}
                      </span>
                </div>
              </template>
            </v-select>
            <b-form-invalid-feedback
              id="invalidFeedbackDelvierySite"
              :state="chkState('selectedConsignee')"
              >Delivery Site is required.</b-form-invalid-feedback
            >
          </b-col>
          <b-col id="colDeliveryPopup" cols="1">
            <i
              id="iDeliverySitePopup"
              class="fa fa-question-circle text-primary bottom-align-text"
              v-b-popover.hover="deliverySitePopoverText"
              title="Delivery Site"
            ></i>
          </b-col>
          <b-col
            id="colAdvancedView"
            v-show="isDeliverySiteSelected"
            cols="12"
            md="4"
            class="text-right"
            v-if="!isHideOnEnhancedBasicMode && isDeliverySiteSelected"
          >
            <b-button
              id="btnIsAdvancedView"
              v-if="isAvailableAdvancedView"
              variant="outline-info"
              size="sm"
              @click="toggleAdvancedView()"
              class="mt-4"
            >
              {{
                isAdvancedView ? "Show Basic Options" : "Show Advanced Options"
              }}
            </b-button>
          </b-col>
        </b-row>

        <b-row
          id="rowShowAfterDivisionSelection"
          v-show="isDeliverySiteSelected"
        >
          <b-col
            id="colShipper"
            v-if="isAdvancedView"
            class="pd-10"
            lg="4"
            md="4"
          >
            <label id="lblShipper">Shipper</label>
            <v-select
              label="name"
              id="ddlShipper"
              @input="onShipperChange"
              :value="selectedShipper"
              :disabled="shippers.length == 0 || matchTemplateShipper"
              :options="shippers"
              :class="
                chkState('selectedShipper') == false ? 'invalid-input' : ''
              "
              placeholder="Select Shipper"
            >
              <template
                id="tmplShipperOption"
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
                <br />
                <span
                  id="spnShipperOptionCityStateText"
                  class="city-state-text"
                >
                  {{ option.address }} {{ option.city }},
                  {{ option.state }}
                </span>
              </template>
            </v-select>
            <b-form-invalid-feedback
              id="invalidFeedbackShipper"
              :state="chkState('selectedShipper')"
              >Shipper is required.</b-form-invalid-feedback
            >
          </b-col>

          <b-col
            id="colSupplier"
            v-if="isAdvancedView && !isHideOnEnhancedBasicMode"
            class="pd-10"
            lg="4"
            md="4"
          >
            <label id="lblSupplier">Supplier</label>
            <v-select
              id="ddlSupplier"
              label="name"
              @input="onSupplierChange"
              :disabled="
                selectedShipper == '' ||
                suppliers.length == 0 ||
                matchTemplateSupplier
              "
              :value="selectedSupplier"
              :options="suppliers"
              :class="
                chkState('selectedSupplier') == false ? 'invalid-input' : ''
              "
              placeholder="Select Supplier"
            >
              <template
                id="tmplSupplierOption"
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
                <br />
                <span
                  id="spnSupplierOptionCityStatetet"
                  class="city-state-text"
                >
                  {{ option.address }} {{ option.city }},
                  {{ option.state }}
                </span>
              </template>
            </v-select>
            <b-form-invalid-feedback
              id="invalidFeedbackSupplier"
              :state="chkState('selectedSupplier')"
              >Supplier is required.</b-form-invalid-feedback
            >
          </b-col>

          <b-col
            id="colAccountOf"
            v-if="isAdvancedView && !isHideOnEnhancedBasicMode"
            class="pd-10"
            lg="4"
            md="4"
          >
            <label id="lblAccountOf">Account Of</label>
            <v-select
              id="ddlAccountOf"
              label="name"
              :disabled="
                selectedSupplier == '' ||
                accountOfs.length == 0 ||
                matchTemplateAccountOf
              "
              :options="accountOfs"
              :value="selectedAccountOf"
              :class="
                chkState('selectedAccountOf') == false ? 'invalid-input' : ''
              "
              @input="onAccountOfChange"
              placeholder="Select Account Of"
            />
            <b-form-invalid-feedback
              id="invalidFeedbackAcountOf"
              :state="chkState('selectedAccountOf')"
              >Account Of is required.</b-form-invalid-feedback
            >
          </b-col>
        </b-row>

        <b-row
          id="rowTwoProductSlideIn"
          class="pd-10"
          v-show="isDeliverySiteSelected"
        >
          <b-col id="colCommodity" class="pd-10" md="7">
            <label id="lblCommodity">Commodity</label>
            <v-select
              id="ddlCommodity"
              label="name"
              @input="onCommodityChange"
              :disabled="isDisabledCommodity || matchTemplateCommodity"
              :options="commodities"
              :value="selectedCommodity"
              :class="
                chkState('selectedCommodity') == false ? 'invalid-input' : ''
              "
              placeholder="Select Commodity"
            />
            <b-form-invalid-feedback
              id="invalidFeedbackCommodity"
              :state="chkState('selectedCommodity')"
              >Commodity is required.</b-form-invalid-feedback
            >
          </b-col>

          <b-col id="colQuantity" class="pd-10" md="5">
            <b-input-group id="inptGrpCallForQuantity">
              <label id="lblQuantity">Quantity</label>

              <b-form-checkbox id="chkbxCallForQuantity"
                               inline
                               @change="setCallForQuantity"
                               :checked="callForQuantity"
                               :disabled="matchTemplateSelectedQuantity"
                               class="ml-3">Call For Quantity</b-form-checkbox>

              <b-col id="colCallforQuantityPopup" cols="1">
                <i id="iCallforQuantitiyPopup"
                   class="fa fa-question-circle text-primary bottom-align-text qty"
                   v-b-popover.hover="callForQuantityPopoverText"
                   title="Call for Quantity"></i>
              </b-col>
            </b-input-group>
            <!--
   amtUOM:{{this.amountUnitOfMeasure}}
  amtUOM:{{amountUnitOfMeasure}}
  amtUOM:{{selectedAmountUnitOfMeasure}}
  amtUOM:{{this.selectedAmountUnitOfMeasure}} -->


            <b-input-group id="inptGrpTxtQuantity">
              <!-- <b-input-group-text slot="append">Gallons</b-input-group-text> -->
              <b-form-input id="txtQuantity"
                            type="number"
                            :disabled="callForQuantity || matchTemplateSelectedQuantity"
                            :value="selectedQuantity"
                            @change="onQuantityChange"
                            :state="chkState('selectedQuantity') == false ? false : null" />

              <template id="tmplAmmountUnitOfMeasure" v-slot:append>
                <b-dropdown :disabled="matchTemplateAmountOfUnitOfMeasure"
                            :text="
                    (amountUnitOfMeasure != ''
                      ? amountUnitOfMeasure
                      : selectedAmountUnitOfMeasure) == ''
                      ? 'GAL'
                      : amountUnitOfMeasure != ''
                      ? amountUnitOfMeasure
                      : selectedAmountUnitOfMeasure
                  "
                            variant="secondary"
                            id="ddlAmountUnitOfMeasure"
                            @change="onAmountUnitOfMeasureChange(amountUnitOfMeasure)">
                  <b-dropdown-item id="drpdnItemGal"
                                   @click="amountUnitOfMeasure = 'GAL'"
                                   :class="{
                      active:
                        (amountUnitOfMeasure != ''
                          ? amountUnitOfMeasure
                          : selectedAmountUnitOfMeasure) == 'GAL',
                    }">
                    GAL
                  </b-dropdown-item>
                  <b-dropdown-item id="drpdnItemLTR"
                                   @click="amountUnitOfMeasure = 'LTR'"
                                   :class="{
                      active:
                        (amountUnitOfMeasure != ''
                          ? amountUnitOfMeasure
                          : selectedAmountUnitOfMeasure) == 'LTR',
                    }">
                    LTR
                  </b-dropdown-item>
                  <b-dropdown-item id="drpdnItemPCS"
                                   @click="amountUnitOfMeasure = 'PCS'"
                                   :class="{
                      active:
                        (amountUnitOfMeasure != ''
                          ? amountUnitOfMeasure
                          : selectedAmountUnitOfMeasure) == 'PCS',
                    }">
                    PCS
                  </b-dropdown-item>
                  <b-dropdown-item id="drpdnItemTON"
                                   @click="amountUnitOfMeasure = 'TON'"
                                   :class="{
                      active:
                        (amountUnitOfMeasure != ''
                          ? amountUnitOfMeasure
                          : selectedAmountUnitOfMeasure) == 'TON',
                    }">
                    TON
                  </b-dropdown-item>
                  <b-dropdown-item id="drpdnItemLBS"
                                   @click="amountUnitOfMeasure = 'LBS'"
                                   :class="{
                      active:
                        (amountUnitOfMeasure != ''
                          ? amountUnitOfMeasure
                          : selectedAmountUnitOfMeasure) == 'LBS',
                    }">
                    LBS
                  </b-dropdown-item>
                  <b-dropdown-item id="drpdnItemKGS"
                                   @click="amountUnitOfMeasure = 'KGS'"
                                   :class="{
                      active:
                        (amountUnitOfMeasure != ''
                          ? amountUnitOfMeasure
                          : selectedAmountUnitOfMeasure) == 'KGS',
                    }">
                    KGS
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-input-group>

            <b-form-invalid-feedback id="invalidFeedbackTxtQuantity"
                                     :state="chkQuantityAmount()">
              Amount must be between {{getMinValueByDivision}} and {{ getMaxValueByDivision }}
            </b-form-invalid-feedback>
            
          </b-col>
          <b-col></b-col>
        </b-row>

        <!-- <b-row>
    <b-col cols="5" class="pt-3">
      <b-form-checkbox
      v-model="canSplash"
      inline
      :disabled="hasQuantity || commodities.length == 0"
      @change="getSplashBlends($event)"
      >Splash Blend</b-form-checkbox
    >
    </b-col>
  </b-row> -->

        <b-row id="rowNoBlends" v-show="isDeliverySiteSelected">
          <p
            id="pNoBlends"
            v-if="noBlends"
            class="invalid-feedback d-block splash-error"
          >
            No availabe products to splash blend
          </p>
        </b-row>

        <!-- START SPLASH BLEND SECTION -->
        <b-row
          id="rowSplashBlends"
          v-show="isDeliverySiteSelected"
          class="pd-10"
          v-if="splashBlends.length > 0"
        >
          <b-col cols="2" class="pd-10">
            <label id="lblSplshBlendsCommodityOne">Commodity</label>
            <input
              class="form-control"
              v-model="commodityOne"
              
            />
          </b-col>

          <b-col id="colCommodityOneGallons" cols="2" class="pd-10">
            <label id="lblCommodityOneGallons"></label>
            <input
              id="inptCommodityOneGallons"
              class="form-control"
              v-model="commodityOneGallons"
             
            />
          </b-col>

          <!-- Commodity One -->
          <b-col id="colCommodityOneShipper" class="pd-10" cols="3">
            <label lbl="lblCommodityOneShipper">Shipper</label>
            <v-select
              label="name"
              id="ddlCommodityOneShipper"
              @input="onCommodityOneShipperChange"
              :value="selectedCommodityOneShipper"
              :options="commodityOneShippers"
              placeholder="Select Shipper"
            >
              <template
                id="tmplCommodityOneShipperOption"
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
                <br />
                <span
                  id="tmplCommodityOneShipperOptionCityStateText"
                  class="city-state-text"
                >
                  {{ option.address }} {{ option.city }},
                  {{ option.state }}
                </span>
              </template>
            </v-select>
          </b-col>

          <b-col id="colCommodityOneSupplier" class="pd-10" cols="3">
            <label id="lblCommodityOneSupplier">Supplier</label>
            <v-select
              label="name"
              id="ddlCommodityOneSupplier"
              @input="onCommodityOneSupplierChange"
              :disabled="
                selectedCommodityOneShipper == '' || suppliers.length == 0
              "
              :value="selectedCommodityOneSupplier"
              :options="commodityOneSuppliers"
              placeholder="Select Supplier"
            >
              <template
                id="tmplCommodityOneSupplierOption"
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
                <br />
                <span
                  id="spnCommodityOneSupplerOptionCityStateText"
                  class="city-state-text"
                >
                  {{ option.address }} {{ option.city }},
                  {{ option.state }}
                </span>
              </template>
            </v-select>
          </b-col>

          <b-col id="colCommodityOneAccountOf" class="pd-10" cols="2">
            <label id="lblCommodityOneAccountOf">Account Of</label>
            <v-select
              id="ddlCommodityOneAccountOf"
              label="name"
              :disabled="
                selectedCommodityOneSupplier == '' || accountOfs.length == 0
              "
              :options="accountOfs"
              :value="selectedCommodityOneAccountOf"
              @input="onCommodityOneAccountOfChange"
              placeholder="Select Account Of"
            />
          </b-col>
          <!-- End Commodity One -->
        </b-row>

        <!-- Commodity Two -->
        <b-row
          id="rowCommodityTwo"
          v-show="isDeliverySiteSelected"
          class="pd-10"
          v-if="splashBlends.length > 0"
        >
          <b-col col="colCommodityTwo" cols="2" class="pd-10">
            <label id="lblCommodityTwo">Commodity</label>
            <input id="inptCommodityTwo"
              class="form-control"
              v-model="commodityTwo"
             />
          </b-col>
          <b-col id="colCommodityTwoaAountUnitofMeasure" cols="2" class="pd-10">
            <label id="lblAmountUnitofMeasure">amountUnitOfMeasure</label>
            <input
              id="inptCommodityTwoGallons"
              class="form-control"
              v-model="commodityTwoGallons"
            
            />
          </b-col>
          <b-col id="colCommodityTwoShipper" class="pd-10" cols="3">
            <label id="lblCommodityTwoShipper">Shipper</label>
            <v-select
              label="name"
              id="ddlCommodityTwoShipper"
              @input="onCommodityTwoShipperChange"
              :value="selectedCommodityTwoShipper"
              :disabled="shippers.length == 0"
              :options="commodityTwoShippers"
              placeholder="Select Shipper"
            >
              <template
                id="tmplCommodityTwoShipperOption"
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
                <br />
                <span
                  id="spnCommodityTwoShipperOptionCityStateText"
                  class="city-state-text"
                >
                  {{ option.address }} {{ option.city }},
                  {{ option.state }}
                </span>
              </template>
            </v-select>
          </b-col>

          <b-col id="colCommoidtyTwoSupplier" class="pd-10" cols="3">
            <label id="lblCommodityTwoSupplier">Supplier</label>
            <v-select
              label="name"
              id="ddlCommodityTwoSupplier"
              @input="onCommodityTwoSupplierChange"
              :disabled="
                selectedCommodityTwoShipper == '' || suppliers.length == 0
              "
              :value="selectedCommodityTwoSupplier"
              :options="commodityTwoSuppliers"
              placeholder="Select Supplier"
            >
              <template
                id="tmplCommodityTwoSupplierOption"
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
                <br />
                <span
                  id="spnCommodityTwoSupplierOptionCityStateText"
                  class="city-state-text"
                >
                  {{ option.address }} {{ option.city }},
                  {{ option.state }}
                </span>
              </template>
            </v-select>
          </b-col>

          <b-col id="colCommidtyTwoAccountOf" class="pd-10" cols="2">
            <label id="lblCommodityTwoAccountOf">Account Of</label>
            <v-select
              id="ddlCommodityTwoAccountOf"
              label="name"
              :disabled="
                selectedCommodityTwoSupplier == '' || accountOfs.length == 0
              "
              :options="accountOfs"
              :value="selectedCommodityTwoAccountOf"
              @input="onCommodityTwoAccountOfChange"
              placeholder="Select Account Of"
            />
          </b-col>
          <!-- End Commodity Two -->
        </b-row>

        <!-- End Splash Blend Section -->

        <b-col id="colEarliestTimeWindow"
               class="pd-10"
               lg="8"
               md="8"
               sm="6"
               v-if="enableTimeWindowsSelection"
               v-show="isDeliverySiteSelected">
          <!-- Load Time -->
          <b-row id="rowEalriestTimewindow">
            <label id="lblEarliestTimeWindow" class="font-weight-bold">Initial Load Time Window</label>
          </b-row>
          <b-row>
            <!-- Earliest Load -->
            <b-col>
              <b-row id="rowEarliestLoad"
                     class="bottom-align-label"
                     align-h="start">
                <label id="lblEarliestLoadTime" class="mr-2">Earliest Time</label>
                <div id="divEarliestLoadDateTime" class="datetime-window">
                  <date-picker v-model="selectedLoadEarliestDate"
                               :disabled="false"
                               type="date"
                               :mode="Mode"
                               :minDate="minLoadEarliestDateTime"
                               :windowValues="true"
                               :division="division"
                               :isEditMode="isEditMode"
                               id="txtLoadEarliestDate"
                               :key="loadEarliestDateKey"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                  <span id="spnEarliestLoadTime" class="ml-1"></span>
                  <date-picker v-model="selectedLoadEarliestTime"
                               :disabled="false"
                               type="time"
                               :mode="Mode"
                               :minDateTime="minLoadEarliestDateTime"
                               :windowValues="true"
                               :division="division"
                               :isEditMode="isEditMode"
                               id="txtLoadEarliestTime"
                               :key="loadEarliestTimeKey"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                </div>
              </b-row>
            </b-col>
            <!-- Latest Load -->
            <b-col>
              <b-row id="rowLatestLoadTime" class="bottom-align-label">
                <label id="lblLastestLoadtime" class="mr-2">Latest Time</label>
                <div id="divLatestLoadDateTime" class="datetime-window">
                  <date-picker v-model="selectedLoadLatestDate"
                               type="date"
                               :mode="Mode"
                               :minDate="minLoadLatestDateTime"
                               :windowValues="true"
                               :division="division"
                               :isEditMode="isEditMode"
                               id="txtLoadLatestDate"
                               :key="loadLatestDateKey"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                  <span id="spnLatestLoadTime" class="ml-1"></span>
                  <date-picker v-model="selectedLoadLatestTime"
                               :disabled="false"
                               type="time"
                               :mode="Mode"
                               :minDateTime="minLoadLatestDateTime"
                               :division="division"
                               :isEditMode="isEditMode"
                               :windowValues="true"
                               id="txtLoadLatestTime"
                               :key="loadLatestTimeKey"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                </div>
                <b-form-invalid-feedback id="invalidFeedbackLoadLatest"
                                         :state="chkLoadLatest()">
                  Latest Date cannot be before the Earliest Date!
                </b-form-invalid-feedback>
              </b-row>
            </b-col>
            
          </b-row>
     
          <!-- Delivery Time Window -->
          <b-row class="mt-4">
            <label id="lblDeliveryTimeWindow" class="font-weight-bold">Scheduled Delivery Time Window</label>
          </b-row>
          <b-row class="mb-4">
            <!-- Earliest Delivery-->
            <b-col>
              <b-row id="rowEarlistDeliveryTime" class="bottom-align-label">
                <label id="lblEarlistDeliveryTime" class="mr-2">Earliest Time</label>
                <div id="divEarlistDeliveryDateTime" class="datetime-window">
                  <date-picker v-model="selectedDeliveryEarliestDate"
                               :disabled="false"
                               type="date"
                               :mode="Mode"
                               :minDate="minDeliveryEarliestDateTime"
                               :windowValues="true"
                               id="txtDeliveryEarliestDate"
                               :key="deliveryEarliestDateKey"
                               @onChange="selectedDeliveryEarliestDateChanged"
                               :invalidState="!chkState('selectedDeliveryEarliestDate')"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                  <span id="spnEarliestDeliveryTime" class="ml-1"></span>
                  <date-picker v-model="selectedDeliveryEarliestTime"
                               :disabled="false"
                               type="time"
                               :mode="Mode"
                               :minDateTime="minDeliveryEarliestDateTime"
                               :windowValues="true"
                               :division="division"
                               :isEditMode="isEditMode"
                               id="txtDeliveryEarliestTime"
                               @onChange="selectedDeliveryEarliestTimeChanged"
                               :key="deliveryEarliestTimeKey"
                               :invalidState="!chkState('selectedDeliveryEarliestTime')"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                </div>

                <div class="invalid-feedback d-block"
                     id="invalidFeedbackEarliestDeliveryDateTime"
                     v-if="
                    !chkState('selectedDeliveryEarliestDate') ||
                    !chkState('selectedDeliveryEarliestTime')
                  ">
                  Delivery Time Earliest is required.
                </div>

              </b-row>
            </b-col>
            <!-- Latest Delivery -->
            <b-col>
              <b-row row="rowLatestDelvieryTime" class="bottom-align-label">
                <label id="lblLatestDeliveryTime" class="mr-2">Latest Time</label>
                <div id="divLatestDeliveryDateTime" class="datetime-window">
                  <date-picker v-model="selectedDeliveryLatestDate"
                               :disabled="false"
                               type="date"
                               :mode="Mode"
                               :minDate="minDeliveryEarliestDateTime"
                               :windowValues="true"
                               id="txtDeliveryLatestDate"
                               :key="deliveryLatestDateKey"
                               :invalidState="!chkState('selectedDeliveryLatestDate')"
                               :state="chkState('selectedDeliveryLatestDate') == false ? false : null"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                  <span spn="spnLatestDeliveryTime" class="ml-1"></span>
                  <date-picker v-model="selectedDeliveryLatestTime"
                               :disabled="false"
                               type="time"
                               :mode="Mode"
                               :windowValues="true"
                               id="txtDeliveryLatestTime"
                               :key="deliveryLatestTimeKey"
                               :invalidState="!chkState('selectedDeliveryLatestTime')"
                               :isRenderFromTemplate="isRenderFromTemplate" />
                </div>
                <div class="invalid-feedback d-block"
                     id="invalidFeedbackLatestDeliveryDateTime"
                     v-if="
                    !chkState('selectedDeliveryLatestDate') ||
                    !chkState('selectedDeliveryLatestTime')
                  ">
                  Delivery Time Latest is required.
                </div>
                
              </b-row>
              <b-form-invalid-feedback id="invalidFeedbackDeliveryLatest"
                                       :state="chkDeliveryLatest()">
                Latest Date cannot be before the Earliest Date!
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-col>

        <b-row id="rowBetween" align-h="between" class="mt-3">
          <b-col id="colBetween" cols="5"> </b-col>
          <b-col id="colNavButtons" cols="5" style="text-align: right">
            <!-- <button type="button" class="btn btn-outline-danger mr-2" @click="clearCommodity()">
          Clear Form
        </button> -->
            <div id="divNavigationalProcessButtons">
              <button
                id="btnCloseWindow"
                type="button"
                class="btn btn-outline-danger mr-2"
                @click="closeWindow()"
              >
                Close
              </button>

              <span
                id="spnAddProductWithToolTip"
                v-if="isAddProdDisabled"
                class="d-inline-block"
                tabindex="0"
                v-b-popover.hover="AddProductButtonPopoverText"
                placement="top"
              >
                <b-button
                  id="btnAddProductTooltip"
                  class="btn btn-success"
                  :disabled="isAddProdDisabled"
                  @click="addProduct()"
                >
                  {{ editIndex == null ? "Add Product" : "Edit Product" }}
                </b-button>
              </span>
              <span id="spnAddProductNotoolTip" v-else-if="!isAddProdDisabled">
                <b-button
                  id="btnAddProduct"
                  class="btn btn-success"
                  :disabled="isAddProdDisabled"
                  @click="addProduct()"
                >
                  {{ editIndex == null ? "Add Product" : "Edit Product" }}
                </b-button>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <vue-snotify id="ntfSpaceforNotifications"></vue-snotify>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required, between, requiredIf } from "vuelidate/lib/validators";
import { handleRequestError } from "@/shared/utils/response-error-handler";

import { divisions, deliveryWindows, minCapacity, maxCapacity } from "@/shared/constants";

import "vue-select/src/scss/vue-select.scss";
import vSelect from "vue-select";
import urls from "@/shared/urls";

import mixin from "@/shared/mixin";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import DatePicker from "@/components/shared/DatePicker";
import moment from "moment";

export default {
  name: "ProductSlideIn.vue",

  props: ["Mode", "editIndex", "Product", "tenderForConsigneeId"],

  components: {
    vSelect,
    Loading,
    DatePicker,
  },

  mixins: [mixin],

  data() {
    return {
      isLoading: false,
      isAdvancedView: false,
      callForQuantity: false,
      hasQuantity: true,
      isSplashBlend: false,
      splashBlends: [],
      canSplash: false,
      noBlends: false,
      commodityOne: "",
      commodityTwo: "",
      commodityOneGallons: 0,
      commodityTwoGallons: 0,
      commodityOnePercentage: "",
      commodityTwoPercentage: "",
      quantity: 0,
      amountUnitOfMeasure: "",
      isAvailableAdvancedView: true,
      isDisabledCommodity: true,
      minLoadEarliestDateTime: null,
      minLoadLatestDateTime: null,
      minDeliveryEarliestDateTime: null,
      minDeliveryLatestDateTime: null,
      loadEarliestDateKey: Math.random(),
      loadEarliestTimeKey: Math.random(),
      loadLatestDateKey: Math.random(),
      loadLatestTimeKey: Math.random(),
      deliveryEarliestDateKey: Math.random(),
      deliveryEarliestTimeKey: Math.random(),
      deliveryLatestDateKey: Math.random(),
      deliveryLatestTimeKey: Math.random(),
      isDeliverySiteSelected: false,
      deliveryWindows,
      maxCapacity,
      minCapacity,
      isHideOnEnhancedBasicMode: false,
      originDates: {
        loadEarliest: "1950-01-01T00:00",
        loadLatest: "2049-12-31T23:59",
      },
    };
  },

  async mounted() {
    if (this.Product) {
      this.callForQuantity =
        this.Product.callForQuantity === null ||
        this.Product.callForQuantity === undefined
          ? this.callForQuantity
          : this.Product.callForQuantity;
    }

    if (this.selectedShipper) {
      this.isAdvancedView = true;
    }
    if (this.selectedConsignee) {
      this.isDeliverySiteSelected = true;
      await this.setDivisionBehaviorRelyingOnDeliverySite();
    }

    await this.enableSelectionCommodity();

    if (!this.isEditMode) {
      await this.setDefaultLoadWindowValues();
      this.setLoadAndDeliveryValues();
    } else {
      this.setEditLoadAndDeliveryValues();
      this.isDisabledCommodity = false;
    }
  },

  methods: {
    getHoursToAdd() {
      switch (this.selectedDeliveryTime) {
        case "morning":
          return 6;
        case "afternoon":
          return 12;
        case "evening":
          return 18;
      }
    },

    // KEY UPDATES
    updateLoadEarliestDateTimePicker() {
      this.loadEarliestDateKey++;
      this.loadEarliestTimeKey++;
    },

    updateLoadLatestDateTimePicker() {
      this.loadLatestDateKey++;
      this.loadLatestTimeKey++;
    },

    updateDeliveryEarliestDateTimePicker() {
      this.deliveryEarliestDateKey++;
      this.deliveryEarliestTimeKey++;
    },

    updateDeliveryLatestDateTimePicker() {
      this.deliveryLatestDateKey++;
      this.deliveryLatestTimeKey++;
    },

    /*
    REACTIVE LOAD DELIVERY DATE TIME PICKERS UPDATES
    This updates the date picker DOM & state based on new minDateTimes
    */
   
    selectedDeliveryEarliestDateChanged(newValue) {
      var newMinDeliveryEarliestDateTime = `${newValue}T${this.selectedDeliveryEarliestTime}`;
      this.reactiveUpdateDeliveryLatestDateTime(newMinDeliveryEarliestDateTime);
    },

    selectedDeliveryEarliestTimeChanged(newValue) {
      var newMinDeliveryEarliestDateTime = `${this.selectedDeliveryEarliestDate}T${newValue}`;
      this.reactiveUpdateDeliveryLatestDateTime(newMinDeliveryEarliestDateTime);
    },

    reactiveUpdateDeliveryLatestDateTime(newMinDeliveryEarliestDateTime = null) {
      if (newMinDeliveryEarliestDateTime === null) {
        this.minDeliveryLatestDateTime = this.minDeliveryEarliestDateTime;
      } else {
        this.minDeliveryLatestDateTime = moment(newMinDeliveryEarliestDateTime);
      }
      this.minDeliveryLatestDateTime.add(this.deliveryWindows.minWindowHours, "hours");

      const parsedNewMinDeliveryEarliest = new Date(newMinDeliveryEarliestDateTime);
   
      const newDeliveryLatestDate = newMinDeliveryEarliestDateTime === null ?
        this.minDeliveryLatestDateTime.startOf("day") :
        this.getMonthDayYearFromDate(parsedNewMinDeliveryEarliest);
      
      const newDeliveryLatestTime = newMinDeliveryEarliestDateTime === null ?
        this.minDeliveryLatestDateTime.toDate().getTime() :
        this.getHourAndMinutesFromDate(parsedNewMinDeliveryEarliest)

      this.$store.dispatch(
        "newOrderTender/setSelectedDeliveryLatestDate",
        newDeliveryLatestDate
      );
   
      this.$store.dispatch(
        "newOrderTender/setSelectedDeliveryLatestTime",
        newDeliveryLatestTime
      );
     
      this.updateDeliveryLatestDateTimePicker();
    },
    getMonthDayYearFromDate(date){
      var month = `${("0" + (date.getMonth() + 1)).slice(-2)}`;
      var day = `${("0" + date.getDate()).slice(-2)}`;
      var year = `${date.getFullYear()}`;

      return new Date(`${month}/${day}/${year}`)
    },
    getHourAndMinutesFromDate(date){
      var hour = `${("0" + (date.getHours() + this.deliveryWindows.minWindowHours)).slice(-2)}`;
      var minutes = `${("0" + date.getMinutes()).slice(-2)}`;
      return `${hour}:${minutes}`;
    },
    // Set initial load and delivery datetime values for new product creation
    setLoadAndDeliveryValues() {
			this.setDeliveryEarliestValues();
			this.setDeliveryLatestValues();
      this.setLoadEarliestValues();
      this.setLoadLatestValues();
    },

    setLoadEarliestValues() {
			if (this.division.toLowerCase() === 'spg') {
				this.minLoadEarliestDateTime = this.minDeliveryEarliestDateTime.clone();
				this.selectedLoadEarliestDate = this.selectedDeliveryEarliestDate;
				this.selectedLoadEarliestTime = this.selectedDeliveryEarliestTime;
			} else {
				if (this.selectedDeliveryTime === "custom" || this.isEditMode) {
					const selectedDateTime = this.selectedDeliveryTime === "custom"
						? `${this.selectedFromCustomDate}T${this.selectedFromCustomTime}`
						: this.selectedDeliveryDate;
					this.minLoadEarliestDateTime = moment(selectedDateTime).add(this.getHoursToAdd(), "hours");
				}
			}
			
			this.updateLoadEarliestDateTimePicker();
		},

    setLoadLatestValues() {
      if (this.division.toLowerCase() === 'spg') {
        this.minLoadLatestDateTime = this.minDeliveryLatestDateTime.clone();
        this.selectedLoadLatestDate = this.selectedDeliveryLatestDate;
        this.selectedLoadLatestTime = this.selectedDeliveryLatestTime;
      } else {
          let minLoadLatestDateTimeTmp = moment(`${this.originDates.loadEarliest}`);
          this.minLoadLatestDateTime = minLoadLatestDateTimeTmp.subtract(2, "days");
      }
      this.updateLoadLatestDateTimePicker();
    },

    setDeliveryEarliestValues() {
      if (this.selectedDeliveryTime === "custom") {
        var deliveryEarliestDate = this.selectedFromCustomDate;
        var deliveryEarliestTime = this.selectedFromCustomTime;
        var minDeliveryEarliestDateTime = `${deliveryEarliestDate}T${deliveryEarliestTime}`;
        this.minDeliveryEarliestDateTime = moment(
          minDeliveryEarliestDateTime
        ).subtract(1, "d");
      } else {
        this.minDeliveryEarliestDateTime = moment(
          this.selectedDeliveryDate
        ).add(this.getHoursToAdd(), "hours");
      }
      this.updateDeliveryEarliestDateTimePicker();
    },

    setDeliveryLatestValues() {
      if (this.selectedDeliveryTime === "custom") {
        var deliveryLatestDate = this.selectedToCustomDate;
        var deliveryLatestTime = this.selectedToCustomTime;
        this.minDeliveryLatestDateTime = moment(
          `${deliveryLatestDate}T${deliveryLatestTime}`
        ).subtract(1, "d");
      } else {
        this.minDeliveryLatestDateTime = moment(
          `${this.selectedDeliveryLatestDate}T${this.selectedDeliveryLatestTime}`
        );
      }
      this.$store.dispatch("newOrderTender/setDeliveryWindow");
      this.updateDeliveryLatestDateTimePicker();
    },

    // Set load and delivery datetime values for edited product
    setEditLoadAndDeliveryValues() {
      const product = this.selectedProducts[this.editIndex];
      // set load Earliest/Latest values for edited product
      if (product) {
      
        let loadDatesConfiguration = this.loadWindow
          .split("/")
          .map((element) => ({
            date: element.split("T")[0],
            time: element.split("T")[1].slice(0, 5),
          }));
   

        this.minloadEarliestDateTime = moment(
          `${loadDatesConfiguration[0].date}T${loadDatesConfiguration[0].time}`
        ).subtract(1, "days");

        //    let minLoadEarliestDateTimeTmp = moment(
  //        `${this.originDates.loadEarliest}`
  //      );
  //      this.minLoadEarliestDateTime = minLoadEarliestDateTimeTmp.subtract(
  //        1,
  //        "days"
  //      );
      
        this.setEditLoadEarliestDate(product, loadDatesConfiguration[0]);
      
        this.setEditLoadEarliestTime(product, loadDatesConfiguration[0]);

     
        this.minloadLatestDateTime = moment(
          `${loadDatesConfiguration[1].date}T${loadDatesConfiguration[1].time}`
        ).subtract(2, "days");

        //    let minLoadLatestDateTimeTmp = moment(
   //       `${this.originDates.loadEarliest}`
    //    );
   //     this.minLoadLatestDateTime = minLoadLatestDateTimeTmp.subtract(
   //       2,
   //       "days"
   //     );
        this.setEditLoadLatestDate(product, loadDatesConfiguration[1]);
        this.setEditLoadLatestTime(product, loadDatesConfiguration[1]);
        
        // // set delivery Earliest/Latest values for edited product
        let deliveryDatesConfiguration = this.deliveryWindow
          .split("/")
          .map((element) => ({
            date: element.split("T")[0],
            time: element.split("T")[1].slice(0, 5),
          }));
        this.minDeliveryEarliestDateTime = moment(
          `${deliveryDatesConfiguration[0].date}T${deliveryDatesConfiguration[0].time}`
        ).subtract(1, "days");
        this.setEditDeliveryEarliestDate(
          product,
          deliveryDatesConfiguration[0]
        );
        this.setEditDeliveryEarliestTime(
          product,
          deliveryDatesConfiguration[0]
        );

        this.minDeliveryLatestDateTime = moment(
          `${deliveryDatesConfiguration[1].date}T${deliveryDatesConfiguration[1].time}`
        ).subtract(2, "days");
        this.setEditDeliveryLatestDate(product, deliveryDatesConfiguration[1]);
        this.setEditDeliveryLatestTime(product, deliveryDatesConfiguration[1]);
      }
    },

    getTimeWindowFromTemplate(timeWindow, windowValue) {
      if (timeWindow != null) {
        let timeWindowValue = timeWindow.split("/");

        return windowValue == "earliest"
          ? timeWindowValue[0].slice(0, -1)
          : timeWindowValue[1].slice(0, -1);
      }
    },

    // Load windows
    setEditLoadEarliestDate(product, earliestConfig) {
  
      if (product.loadEarliestDate) {
        this.selectedLoadEarliestDate = product.loadEarliestDate;
      } else {
        this.selectedLoadEarliestDate = earliestConfig.date;
      }
      this.loadEarliestDateKey++;
    },

    setEditLoadEarliestTime(product, loadEarliestConfig) {
      if (product.loadEarliestTime) {
        this.selectedLoadEarliestTime = product.loadEarliestTime;
      } else {
        this.selectedLoadEarliestTime =
          loadEarliestConfig.time;
      }
      this.loadEarliestDateKey++;
    },

    setEditLoadLatestDate(product, latestConfig) {
      if (product.loadLatestDate) {
        this.selectedLoadLatestDate = product.loadLatestDate;
      } else {
        this.selectedLoadLatestDate = latestConfig.date;
      }
      this.loadLatestDateKey++;
    },

    setEditLoadLatestTime(product, latestConfig) {
      if (product.loadLatestTime) {
        this.selectedLoadLatestTime = product.loadLatestTime;
      } else {
        this.selectedLoadLatestTime = latestConfig.time;
      }
      this.loadLatestTimeKey++;
    },

    // Delivery windows
    setEditDeliveryEarliestDate(product, earliestConfig) {
      if (product.deliveryEarliestDate) {
        this.selectedDeliveryEarliestDate = product.deliveryEarliestDate;
      } else {
        this.selectedDeliveryEarliestDate = earliestConfig.date;
      }
      this.deliveryEarliestDateKey++;
    },

    setEditDeliveryEarliestTime(product, earliestConfig) {
      if (product.deliveryEarliestTime) {
        this.selectedDeliveryEarliestTime = product.deliveryEarliestTime;
      } else {
        this.selectedDeliveryEarliestTime = earliestConfig.time;
      }
      this.deliveryEarliestTimeKey++;
    },

    setEditDeliveryLatestDate(product, latestConfig) {
      if (product.deliveryLatestDate) {
        this.selectedDeliveryLatestDate = product.deliveryLatestDate;
      } else {
        this.selectedDeliveryLatestDate = latestConfig.date;
      }
      this.deliveryLatestDateKey++;
    },

    setEditDeliveryLatestTime(product, latestConfig) {
      if (product.deliveryLatestTime) {
        this.selectedDeliveryLatestTime = product.deliveryLatestTime;
      } else {
        this.selectedDeliveryLatestTime = latestConfig.time;
      }
      this.deliveryLatestTimeKey++;
    },

    resetValuesAdvanceView() {
      this.$v.$reset();
      this.$store.dispatch("newOrderTender/setSelectedSupplier", "");
      this.$store.dispatch("newOrderTender/setSelectedAccountOf", "");
      this.$store.dispatch("newOrderTender/setSelectedCommodity", "");
    },

    toggleAdvancedView() {
      this.resetValuesAdvanceView();
      this.isAdvancedView = !this.isAdvancedView;
    },

    async onDeliverySiteChanged(value) {
      try {
				if(value.consigneeId) value.id = value.consigneeId;
        var consignee={...value, address1: value.address}
        this.$store.dispatch("newOrderTender/setSelectedConsignee", consignee);
        this.$store.dispatch("newOrderTender/clearProductDetails");

        if (value) {
          this.isDeliverySiteSelected = true;
          await this.setDivisionBehaviorRelyingOnDeliverySite();
          await this.$store.dispatch("newOrderTender/getCommodities", {
            isAdvancedView: this.isAdvancedView,
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: value.id,
          });

          await this.$store.dispatch("newOrderTender/getShippers", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: value.id,
          });

          if (!this.isAdvancedView) {
            this.enableSelectionCommodity();
          }
        } else {
          this.isAvailableAdvancedView = true;
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    addProduct() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.isOrderAmountOverCapacity(this.selectedQuantity, this.editIndex)) {
          let maxAvailableCapacity = this.maxCapacity.FDG;
          if (this.division === divisions.SPG) {
            maxAvailableCapacity = this.maxCapacity.SPG;
          }

          this.$snotify.error(
            `Product not added! Total quantity of order exceeds ${maxAvailableCapacity} ${this.amountUnitOfMeasure}.`,
            "Error",
            {
              timeout: 5000,
              showProgressBar: false,
            }
          );
          return;
        }

        this.$v.$reset();

        var aum = "GAL";
        if (this.amountUnitOfMeasure != "") {
          aum = this.amountUnitOfMeasure;
          this.$store.dispatch(
            "newOrderTender/setSelectedAmountUnitOfMeasure",
            this.amountUnitOfMeasure
          );
        } else if (this.selectedAmountUnitOfMeasure != "") {
          aum = this.selectedAmountUnitOfMeasure;
          this.$store.dispatch(
            "newOrderTender/setSelectedAmountUnitOfMeasure",
            this.selectedAmountUnitOfMeasure
          );
        } else {
          if (aum == "") aum = "GAL";

          this.$store.dispatch("newOrderTender/setSelectedAmountUnitOfMeasure", aum);
        }
        var objProduct = {
          product: {
            deliverySite: this.selectedConsignee,
            shipper: this.selectedShipper,
            supplier: this.selectedSupplier,
            accountOf: this.selectedAccountOf,
            product: this.selectedCommodity,
            amount: this.selectedQuantity,
            amountUnitOfMeasure: aum,
            callForQuantity: this.callForQuantity,
            commodityOne: this.commodityOne,
            commodityTwo: this.commodityTwo,
            commodityOneGallons: 0,
            commodityTwoGallons: 0,
            commodityOnePercentage: this.commodityOnePercentage,
            commodityTwoPercentage: this.commodityTwoPercentage,
            commodityOneShipper: this.selectedCommodityOneShipper,
            commodityOneSupplier: this.selectedCommodityOneSupplier,
            commodityOneAccountOf: "unknown",
            commodityTwoShipper: this.selectedCommodityTwoShipper,
            commodityTwoSupplier: this.selectedCommodityTwoSupplier,
            commodityTwoAccountOf: "unknown",
            isAdvancedView: this.isAdvancedView,

            deliveryWindow: this.deliveryWindow,

            minLoadEarliestDateTime: this.minLoadEarliestDateTime
              ? this.minLoadEarliestDateTime.format()
              : null,
            loadEarliestDate: this.selectedLoadEarliestDate,
            loadEarliestTime: this.selectedLoadEarliestTime,

            minLoadLatestDateTime: this.minLoadLatestDateTime
              ? this.minLoadLatestDateTime.format()
              : null,
            loadLatestDate: this.selectedLoadLatestDate,
            loadLatestTime: this.selectedLoadLatestTime,

            loadWindow: this.loadWindow,
            minDeliveryEarliestDateTime: this.minDeliveryEarliestDateTime
              ? moment(this.minDeliveryEarliestDateTime).format('YYYY-MM-DDTHH:mm:ss')
              : null,
            deliveryEarliestDate: this.selectedDeliveryEarliestDate,
            deliveryEarliestTime: this.selectedDeliveryEarliestTime,

            minDeliveryLatestDateTime: this.minDeliveryLatestDateTime
              ? moment(this.minDeliveryLatestDateTime).format('YYYY-MM-DDTHH:mm:ss')
              : null,
            deliveryLatestDate: this.selectedDeliveryLatestDate,
            deliveryLatestTime: this.selectedDeliveryLatestTime,
          },
          productIndex: this.editIndex != null ? this.editIndex : null,
        };

        this.showModal = false;
        this.$store.dispatch("newOrderTender/addProduct", objProduct);
        this.$store.dispatch("newOrderTender/clearProductDetails");
        this.canSplash = false;

        if (this.editIndex != null) this.$emit("closePanel", {});
        else {
          this.clearCommodity();
          this.$snotify.success("Product added successfully.", "Success", {
            timeout: 1000,
            showProgressBar: false,
          });
        }

        this.$root.$emit("toggleEditIndex", null);
        this.$root.$emit("refreshProductsTable", null);
      }
    },

    clearCommodity() {
      this.callForQuantity = false;
      this.commodityOne = "";
      this.commodityTwo = "";
      this.commodityOneGallons = "";
      (this.commodityTwoGallons = ""), (this.commodityOnePercentage = "");
      this.commodityTwoPercentage = "";
      this.commodityOneChildCode = "";
      this.commodityTwoChildCode = "";
      this.$store.dispatch("newOrderTender/setSelectedAmountUnitOfMeasure", "GAL");
      this.splashBlends = [];
      this.canSplash = false;
      this.$store.dispatch("newOrderTender/clearProductDetails");
    },

    deleteProduct(index) {
      this.$store.dispatch("newOrderTender/deleteProduct", index);
    },

    async onShipperChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setSelectedSupplier", "");
        this.$store.dispatch("newOrderTender/setSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setSelectedShipper", value);

        if (this.selectedShipper) {
          await this.$store.dispatch("newOrderTender/getSuppliers", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id,
            shipper: this.selectedShipper.id,
          });
        }
        this.enableSelectionCommodity();
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    async onCommodityOneShipperChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setCommodityOneSelectedSupplier", "");
        this.$store.dispatch("newOrderTender/setCommodityOneSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setCommodityOneSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setCommodityOneSelectedShipper", value);

        if (this.selectedCommodityOneShipper) {
          await this.$store.dispatch(
            "newOrderTender/getSuppliers",
            {
              jwtToken: this.$store.state.user.jwtToken,
              billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedCommodityOneShipper.id,
            }
          );
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    async onCommodityTwoShipperChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setCommodityTwoSelectedSupplier", "");
        this.$store.dispatch("newOrderTender/setCommodityTwoSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setCommodityTwoSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setCommodityTwoSelectedShipper", value);

        if (this.selectedCommodityTwoShipper) {
          await this.$store.dispatch(
            "newOrderTender/getSuppliers",
            {
              jwtToken: this.$store.state.user.jwtToken,
              billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedCommodityTwoShipper.id,
            }
          );
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    async onSupplierChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setSelectedSupplier", value);

        if (this.selectedSupplier) {
          await this.$store.dispatch("newOrderTender/getAccountOfs", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id,
            shipper: this.selectedShipper.id,
            supplier: this.selectedSupplier.id,
          });
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    async onCommodityOneSupplierChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setCommodityOneSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setCommodityOneSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setCommodityOneSelectedSupplier", value);

        if (this.selectedCommodityOneSupplier) {
          await this.$store.dispatch("newOrderTender/getAccountOfs", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id,
            shipper: this.selectedShipper.id,
            supplier: this.selectedSupplier.id,
          });
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    async onCommodityTwoSupplierChange(value) {
      try {
        this.$store.dispatch("newOrderTender/setCommodityTwoSelectedAccountOf", "");
        this.$store.dispatch("newOrderTender/setCommodityTwoSelectedCommodity", "");
        this.$store.dispatch("newOrderTender/setCommodityTwoSelectedSupplier", value);

        if (this.selectedCommodityTwoSupplier) {
          await this.$store.dispatch("newOrderTender/getAccountOfs", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTender.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id,
            shipper: this.selectedShipper.id,
            supplier: this.selectedSupplier.id,
          });
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    onAccountOfChange(value) {
      this.$store.dispatch("newOrderTender/setSelectedCommodity", "");
      this.$store.dispatch("newOrderTender/setSelectedAccountOf", value);

      this.enableSelectionCommodity();
    },

    onCommodityOneAccountOfChange(value) {
      this.$store.dispatch("newOrderTender/setCommodityOneSelectedCommodity", "");
      this.$store.dispatch("newOrderTender/setCommodityOneSelectedAccountOf", value);
    },

    onCommodityTwoAccountOfChange(value) {
      this.$store.dispatch("newOrderTender/setCommodityTwoSelectedCommodity", "");
      this.$store.dispatch("newOrderTender/setCommodityTwoSelectedAccountOf", value);
    },

    onCommodityChange(value) {
      this.$store.dispatch("newOrderTender/setSelectedCommodity", value);
    },

    onSplashCommodityChange(value) {
      this.$store.dispatch("newOrderTender/setSelectedSplashCommodity", value);
    },

    onQuantityChange(value) {
      this.hasQuantity = false;
      this.quantity = value;
      this.$store.dispatch("newOrderTender/setSelectedQuantity", value);
    },

    onAmountUnitOfMeasureChange(value) {
      if (value == "") value = "GAL";

      this.amountUnitOfMeasure = value;
      this.$store.dispatch("newOrderTender/setSelectedAmountUnitOfMeasure", value);
    },

    chkState(val) {
      const field = this.$v[val];
      if (field !== undefined) {
        return !field.$dirty || !field.$invalid;
      }
      return true;
    },

		chkStateCustomTime(val) {
      const field = this.$v[val];
      if (field !== undefined) {
        return field.$dirty || field.$invalid;
      }
      return true;
    },

    chkQuantityAmount() {
      if (this.selectedQuantity !== "" && !this.callForQuantity) {
        if (
          this.selectedQuantity < this.getMinValueByDivision ||
          this.selectedQuantity > this.getMaxValueByDivision
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    chkDeliveryLatest() {
    
      if (this.isAdvancedView) {
        if (this.selectedDeliveryEarliestDate > this.selectedDeliveryLatestDate) {
          return false;
        }
        else if ((this.selectedDeliveryEarliestDate == this.selectedDeliveryLatestDate) &&
          (this.selectedDeliveryEarliestTime > this.selectedDeliveryLatestTime)) {
          return false;

        }
        else {
          return true;
        }
      }
      else {
        return true
      }
    },

    chkLoadLatest() {
      if (this.isAdvancedView) {
        if (this.selectedLoadEarliestDate > this.selectedLoadLatestDate) {
          return false;
        }
        else if ((this.selectedLoadEarliestDate == this.selectedLoadLatestDate) &&
          (this.selectedLoadEarliestTime > this.selectedLoadLatestTime)) {
          return false;

        }
        else {
          return true;
        }
      }
      else {
        return true;
      }
    },

    closeWindow() {
      this.$emit("closePanel", {});
    },

    isOrderAmountOverCapacity(amount, editIndex) {
      var totalAmount = 0;

      for (var i = 0; i < this.selectedProducts.length; i++) {
        if (editIndex === i) {
          totalAmount += parseInt(amount);
        } else {
          if (!this.selectedProducts[i].callForQuantity)
            totalAmount += parseInt(this.selectedProducts[i].amount);
        }
      }

      if (editIndex === undefined || editIndex === null) {
        totalAmount = parseInt(totalAmount) + parseInt(amount);
      }

      switch (this.currentBehaviorRelyingOnData) {
        case divisions.SPG: {
          if (totalAmount > this.maxCapacity.SPG) {
            return true;
          }
          break;
        }
        case divisions.FDG: {
          if (totalAmount > this.maxCapacity.FDG) {
            return true;
          }
          break;
        }
      }

      return false;
    },

    getDeliveryLabel(option) {
      return this.getDeliveryAddress(option);
    },

    setCallForQuantity(val) {
      this.callForQuantity = val;
      this.$store.dispatch("newOrderTender/setSelectedQuantity", "");
    },

    async getSplashBlends($event) {
      this.isLoading = true;
      let canBlend = this.$store.getters["newOrderTender/getSelectedCommodity"].id;

      fetch(urls.DevSplashBlendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(canBlend),
      })
        .then((response) => response.json())
        .then((data) => {
          this.splashBlends = data;
          if (this.splashBlends.length > 0) {
            this.commodityOne = this.splashBlends[0].child_Ccl_Code;
            this.commodityTwo = this.splashBlends[1].child_Ccl_Code;
            this.commodityOnePercentage = this.splashBlends[0].percentage;
            this.commodityTwoPercentage = this.splashBlends[1].percentage;
            this.commodityOneGallons =
              (+this.splashBlends[0].percentage / 100) * (+this.quantity).toFixed(2);
            this.commodityTwoGallons = (
              +this.quantity - +this.commodityOneGallons
            ).toFixed(2);
          }
          this.isSplashBlend = this.splashBlends.length > 0 ? true : false;
          this.noBlends = this.splashBlends.length == 0 ? true : false;
          this.isLoading = false;

          if ($event == false) {
            this.splashBlends = [];
          }
        });
    },

    async setDivisionBehaviorRelyingOnDeliverySite() {
      this.setEnhancedBasicMode(this.division);
    },

    setEnhancedBasicMode(division) {
      switch (division) {
        case divisions.SPG:
          this.isAdvancedView = true;
          this.isHideOnEnhancedBasicMode = true;
          break;
      }
    },

    enableSelectionCommodity() {
      switch (this.division) {
        case divisions.SPG:
          this.isDisabledCommodity = !(
            (this.isAdvancedView && this.selectedShipper != null) ||
            this.commodities.length == 0
          );
          break;

        default:
        case divisions.FDG:
          this.isDisabledCommodity =
            (this.isAdvancedView && this.selectedAccountOf == "") ||
            this.commodities.length == 0;
          break;
      }
    },

    customBetweenRelyingDivision() {
      switch (this.division) {
        case divisions.SPG:
          return between(this.minCapcity.SPG, this.maxCapacity.SPG);
        default:
        case divisions.FDG:
          return between(this.minCapacity.FDG, this.maxCapacity.FDG);
      }
    },

    async setDefaultLoadWindowValues() {
      if (!this.$store.state.newOrderTender.isEditMode) {
        await this.$store.dispatch("newOrderTender/setSelectedLoadEarliestDate", "");
        await this.$store.dispatch("newOrderTender/setSelectedLoadEarliestTime", "");
        await this.$store.dispatch("newOrderTender/setSelectedLoadLatestDate", "");
        await this.$store.dispatch("newOrderTender/setSelectedLoadLatestTime", "");
      }
    },

		customTimeValidation() {
			const earliestTimeInMinutes = this.convertTimeToMinutes(this.selectedDeliveryEarliestTime);
			const latestTimeInMinutes = this.convertTimeToMinutes(this.selectedDeliveryLatestTime);

			//  if (earliestTimeInMinutes >= latestTimeInMinutes) {
			//  	return false;
			//  }
      return true;
    },

    convertTimeToMinutes(time) {
      const [hours, minutes] = time.split(':');
      return parseInt(hours) * 60 + parseInt(minutes);
    },
  },

  computed: {
		filteredConsigneesWithKey(){
			return this.filteredConsignees.map((item, index) => ({
        ...item,
        id: `${item.id}_${index}`, // Use a combination of id and index as the key
				consigneeId: item.id
      }));
		},

    ...mapState("newOrderTender", [
      "consignees",
      "commodities",
      "shippers",
      "commodityOneShippers",
      "commodityTwoShippers",
      "suppliers",
      "commodityOneSuppliers",
      "commodityTwoSuppliers",
      "accountOfs",
      "selectedConsignee",
      "selectedProducts",
      "selectedShipper",
      "selectedCommodityOneShipper",
      "selectedCommodityTwoShipper",
      "selectedSupplier",
      "selectedCommodityOneSupplier",
      "selectedCommodityTwoSupplier",
      "selectedAccountOf",
      "selectedCommodityOneAccountOf",
      "selectedCommodityTwoAccountOf",
      "selectedCommodity",
      "selectedSplashCommodity",
      "selectedQuantity",
      "selectedAmountUnitOfMeasure",
      "selectedLoadEarliestDate",
      "selectedLoadEarliestTime",
      "selectedLoadLatestDate",
      "selectedLoadLatestTime",
      "loadWindow",
      "selectedDeliveryEarliestDate",
      "selectedDeliveryEarliestTime",
      "selectedDeliveryLatestDate",
      "selectedDeliveryLatestTime",
      "deliveryWindow",
      "isEditMode",
      "selectedDeliveryDate",
      "selectedDeliveryTime",
      "selectedFromCustomDate",
      "selectedFromCustomTime",
      "selectedToCustomDate",
      "selectedToCustomTime",
      "templateId",
      "division",
    ]),

    ...mapGetters("newOrderTender", ["filteredConsignees"]),

    deliverySitePopoverText() {
      return 'After products are added, delivery sites will be filtered based on the "Bill To" of the first delivery site.  If you would like to add a delivery site of another bill to, please create a separate order.';
    },

    callForQuantityPopoverText() {
      return "If you are not sure of the extact quantity you need, you can check this box. You can then come back later and edit the order with an updated quantity.";
    },

    AddProductButtonPopoverText() {
      return "You must add all required fields before being able to add a product, inlcuding Delivery Site, Commodity, and Quantity/Call For Quantity.";
    },

    enableTimeWindowsSelection() {
      switch (this.division) {
        case divisions.SPG:
          return true;
        case divisions.FDG:
          return this.isAdvancedView;
      }
    },

    selectedLoadEarliestDate: {
      get() {
        return this.$store.state.newOrderTender.selectedLoadEarliestDate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedLoadEarliestDate", value);
      },
    },

    selectedLoadEarliestTime: {
      get() {
        return this.$store.state.newOrderTender.selectedLoadEarliestTime;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedLoadEarliestTime", value);
      },
    },

    selectedLoadLatestDate: {
      get() {
        return this.$store.state.newOrderTender.selectedLoadLatestDate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedLoadLatestDate", value);
      },
    },

    selectedLoadLatestTime: {
      get() {
        return this.$store.state.newOrderTender.selectedLoadLatestTime;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedLoadLatestTime", value);
      },
    },

    selectedDeliveryEarliestDate: {
      get() {
        return this.$store.state.newOrderTender.selectedDeliveryEarliestDate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedDeliveryEarliestDate", value);
      },
    },

    selectedDeliveryEarliestTime: {
      get() {
        return this.$store.state.newOrderTender.selectedDeliveryEarliestTime;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedDeliveryEarliestTime", value);
      },
    },
    selectedDeliveryLatestDate: {
      get() {
        return this.$store.state.newOrderTender.selectedDeliveryLatestDate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedDeliveryLatestDate", value);
      },
    },

    selectedDeliveryLatestTime: {
      get() {
        return this.$store.state.newOrderTender.selectedDeliveryLatestTime;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSelectedDeliveryLatestTime", value);
      },
    },

    isEditMode() {
      return this.$store.state.newOrderTender.isEditMode;
    },

    isAddProdDisabled() {
      if (!this.$v.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    getMinValueByDivision() {
      switch (this.division) {
        case divisions.SPG:
          return this.minCapacity.SPG;
        default:
        case divisions.FDG:
          return this.minCapacity.FDG;
      }
    },
    getMaxValueByDivision() {
      switch (this.division) {
        case divisions.SPG:
          return this.maxCapacity.SPG;
        default:
        case divisions.FDG:
          return this.maxCapacity.FDG;
      }
    },

    isRenderFromTemplate() {
      return false; // this.templateId != undefined //this will need fixed to account for ad hoc and template orders;
    },

    matchTemplateDeliverySite() {
      if (this.isRenderfromTemplate) {
        if (
          this.editIndex != undefined &&
          this.$store.state.newOrderTender.selectedProducts[this.editIndex]
            .deliverySite != undefined &&
          this.$store.state.orderTemplate.orderTemplates.find(
            (t) => t.templateId == this.templateId
          ).details != undefined
        ) {
          let templateDeliverySiteId =
            this.$store.state.newOrderTender.selectedProducts[this.editIndex]
              .deliverySite.id;
          let newOrderDeliverySiteId =
            this.$store.state.orderTemplate.orderTemplates.find(
              (t) => t.templateId == this.templateId
            ).details[this.editIndex].deliverySite.id;

          return templateDeliverySiteId === newOrderDeliverySiteId;
        }
        return false;
      }
      return false;
    },

    matchTemplateShipper() {
      if (this.isRenderfromTemplate == false) {
        return false;
      }
      if (
        this.isRenderFromTemplate &&
        this.editIndex != undefined &&
        this.$store.state.newOrderTender.selectedProducts[this.editIndex].shipper == null
      )
        return false;

      if (this.isRenderFromTemplate) {
        if (
          this.$store.state.orderTemplate.orderTemplates.find(
            (t) => t.templateId == this.templateId
          ).details != undefined &&
          this.$store.state.newOrderTender.selectedProducts[this.editIndex]
            .shipper.id ===
            this.$store.state.orderTemplate.orderTemplates.find(
              (t) => t.templateId == this.templateId
            ).details[this.editIndex].shipper.id &&
          this.$store.state.newOrderTender.selectedProducts[this.editIndex]
            .amount != null
        ) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    matchTemplateSupplier() {
      return false;
    },

    matchTemplateAccountOf() {
      return false;
    },

    matchTemplateCommodity() {
      if (this.isRenderfromTemplate) {
        if (
          this.isRenderFromTemplate &&
          this.editIndex != undefined &&
          this.$store.state.orderTemplate.orderTemplates.find(
            (t) => t.templateId == this.templateId
          ).details != undefined &&
          this.$store.state.newOrderTender.selectedCommodity != undefined
        ) {
          let orderCommodity =
            this.$store.state.newOrderTender.selectedCommodity;
          let templateCommodity =
            this.$store.state.orderTemplate.orderTemplates.find(
              (t) => t.templateId === this.templateId
            ).details[this.editIndex].product;
          if (
            orderCommodity != undefined &&
            orderCommodity != "" &&
            templateCommodity != undefined &&
            templateCommodity != ""
          )
            return (
              orderCommodity.id === templateCommodity.id && templateCommodity.id != null
            );
        }
        return false;
      }
      return false;
    },

    matchTemplateSelectedQuantity() {
      if (this.isRenderfromTemplate) {
        if (this.editIndex != undefined) {
          let orderAmount = this.$store.state.newOrderTender.selectedProducts[
            this.editIndex
          ];
          let templateAmount = this.$store.state.orderTemplate.orderTemplates.find(
            (t) => t.templateId == this.templateId
          ).details[this.editIndex];
          if (
            orderAmount != undefined &&
            orderAmount != null &&
            templateAmount != undefined &&
            templateAmount != null
          ) {
            return (
              orderAmount.amount === templateAmount.amount &&
              templateAmount.amount > 0
            );
          }
          return false;
        }
        return false;
      }
      return false;
    },

    matchTemplateAmountOfUnitOfMeasure() {
      if (this.isRenderfromTemplate) {
        if (this.isRenderFromTemplate && this.editIndex != undefined) {
          let orderAmountOfUnitOfMeasure = this.$store.state.newOrderTender
            .selectedProducts[this.editIndex];

          let templateAmountOfUnitOfMeasure = this.$store.state.orderTemplate.orderTemplates.find(
            (t) => t.templateId == this.templateId
          ).details[this.editIndex].product;
          if (
            orderAmountOfUnitOfMeasure != undefined &&
            templateAmountOfUnitOfMeasure != undefined
          ) {
            if (
              orderAmountOfUnitOfMeasure.amountUnitOfMeasure ===
                templateAmountOfUnitOfMeasure &&
              templateAmountOfUnitOfMeasure != ""
            ) {
              return true;
            }
          }
          return false;
        }
      }
      return false;
    },

    matchTemplateCommodityOneAccountOf() {
      return false;
    },

    matchTemplateCommodityTwoShipper() {
      return false;
    },

    matchTemplateCommodityTwoSupplier() {
      return false;
    },

    matchTemplateCommodityTwoAccountOf() {
      return false;
    },
  },

  watch: {
    consignees: function (val) {
      if (val.length > 0 && this.tenderForConsigneeId) {
        this.onDeliverySiteChanged(
          this.consignees.find((c) => c.id == this.tenderForConsigneeId)
        );
      }
    },

    isAddProdDisabled: function () {
      this.onChange();
    },
  },
	
  validations() {
    if (!this.isAdvancedView) {
      return {
        selectedConsignee: {
          required,
        },
        selectedQuantity: {
          // eslint-disable-next-line no-unused-vars
          required: requiredIf(function (form) {
            return !this.callForQuantity;
          }),
          between: this.chkQuantityAmount,
        },
        selectedCommodity: {
          required,
        },
      };
    } else {
      if (this.division == divisions.SPG) {
        return {
          selectedConsignee: {
            required,
          },
          selectedQuantity: {
            // eslint-disable-next-line no-unused-vars
            required: requiredIf(function (form) {
              return !this.callForQuantity;
            }),
            between: this.chkQuantityAmount,
          },
          selectedShipper: {
            required,
          },
          selectedCommodity: {
            required,
          },
          selectedDeliveryEarliestDate: {
            required,
            between: this.chkDeliveryLatest
          },
          selectedDeliveryEarliestTime: {
            required,
            between: this.chkDeliveryLatest
          },
          selectedDeliveryLatestDate: {
            required,
            between: this.chkDeliveryLatest

          },
          selectedDeliveryLatestTime: {
            required,
            between: this.chkDeliveryLatest,
						customTimeValidation: () => this.customTimeValidation(),
          },
          selectedLoadEarliestDate: {
            between: this.chkLoadLatest,
          },
          selectedLoadEarliestTime: {
            between: this.chkLoadLatest,
          },
          selectedLoadLatestDate: {
            between: this.chkLoadLatest,
          },
          selectedLoadLatestTime: {
            between: this.chkLoadLatest,
          }
        };
      } else {
        return {
          selectedConsignee: {
            required,
          },
          selectedQuantity: {
            required: requiredIf(function () {
              return !this.callForQuantity;
            }),
            between: this.chkQuantityAmount,
          },
          selectedCommodity: {
            required,
          },
          selectedShipper: {
            required,
          },
          selectedSupplier: {
            required,
          },
          selectedAccountOf: {
            required,
          },
          selectedLoadEarliestDate: {
            required,
          },
          selectedLoadEarliestTime: {
            required,
          },
          selectedLoadLatestDate: {
            required,
          },
          selectedLoadLatestTime: {
            required,
          },
          selectedDeliveryEarliestDate: {
            required,
          },
          selectedDeliveryEarliestTime: {
            required
          },
          selectedDeliveryLatestDate: {
            required,
          },
          selectedDeliveryLatestTime: {
            required,
						customTimeValidation: () => this.customTimeValidation(),
          },
        };
      }
    }
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}

.card-box-shadow {
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
}
.active {
  background: #97a8a5;
}
.grey-background {
  padding-top: 65px;
  background: #e4e5e6;
  height: 100%;
}

.bottom-align-text {
  position: absolute;
  bottom: 7.5px;
  left: 0;
  font-size: 20px;
}

.invalid-input {
  border: 0.5px solid #c22032;
  border-radius: 5px;
}
.splash-error {
  margin-top: 5px;
  margin-left: 17px;
}

.wordwrap {
  white-space: pre-wrap !important;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  /* max-width: 250px; */
}

.bottom-align-label {
  align-items: flex-end;
  font-weight: lighter;
}

.datetime-window {
  display: flex;
  width: 230px;
}

@media (max-width: 993px) and (min-width: 550px) {
  .qty {
    position: absolute !important;
    left: 200px !important;
  }
}
</style>
