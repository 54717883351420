<template id="tmplDatePicker">
  <div id="divDatePicker" style="max-width: 225px;">
    <date-picker
      id="txtDatePicker"
      :class="{ 'invalid-input': invalidInput }"
      v-model="selectedValue"
      :disabled="disabled"
      :config="options"
      @dp-hide="onHide"
      @dp-change="onChange"
      :placeholder="placeholder"      
      :wrap="false"
    />
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import moment from "moment";
import { dateTimeFormats, deliveryWindows } from "@/shared/constants";

const steppingNumber = 1;
// this flag will work on the init selected date and minDate
const modes = { edit: "edit", create: "create", none: "none" }; // none -- there is no mindate and no default selectedvalue (defaultDay)

// all input/output dates are in string format, in fact, can be date/moment format
const defaultDay = (fmt, hours) =>
  moment()
    .add((hours || 0) + deliveryWindows.startOffsetHours, "hours")
    .format(fmt || dateTimeFormats.datetimeFormat); // logic changed to default to today

export default {
	name: "DatePicker.vue",

  props: {
    type: { type: String, default: "datetime" },
    mode: null, // create/edit/none mode
    value: null,
    disabled: { type: Boolean, default: false },
    minDate: null, // optional moment datetime
    maxDate: null, // optional moment datetime
    id: "", // get id from parent and easy for debug
    windowValues: { type: Boolean, default: false },
    invalidState: { type: Boolean, default: false },
    division: { type: String },
    isEditMode: { type: Boolean, default: false },
    minDateTime: null,
    isRenderFromTemplate: { type: Boolean, default: false},
  },

	data() {
    return {
			/**@type{Object} url: https://momentjs.com/docs/#/displaying/ */
      options: {
        format: dateTimeFormats.datetimeFormat,
        useCurrent: false,
        showClear: true,
        showClose: true,
        stepping: steppingNumber,
        minDate: moment("01/01/1950"),
        allowInputToggle: true,
        widgetPositioning: { horizontal: "auto", vertical: "bottom" },
        maxDate: moment("12/31/2199")
      },
      selectedValue: "",
      format: "",
      outputFormat: "",
      dataMode: modes.none,
      placeholder: ""
    }
  },

  created() {
    // init all params
    this.dataMode = Object.keys(modes).find(
      key => modes[key] === (this.mode || "none").toLowerCase()
    ); // if there is a value, it is in edit mode
    if (this.windowValues) {
      this.dataMode = this.isEditMode ? "edit" : "create"
    }
    this.format = this.getFormat();
    this.outputFormat = this.getOutputFormat();
    this.placeholder = this.format.toLowerCase(); // just use the format; can be use differently

    // overwrite options based on input
    this.options.useCurrent = this.dataMode !== modes.none; // on none mode, do not set anything    
    this.options.format = this.format;
    this.options.minDate =
      this.dataMode === modes.none ||
			this.minDate === null ||
			this.minDate === undefined
        ? moment("01/01/1950")
        : this.minDate;

    if (!this.isEditMode && this.type === "time" && this.windowValues) {
      this.options.minDate = this.minDateTime
    }
    if (this.isEditMode && this.type === "time" && this.windowValues) {
      this.options.minDate = this.minDate
      this.selectedValue =  moment(
            this.value,
            "hh:mm A"
          ).format(this.format)
    } else {
      this.selectedValue =
      (this.dataMode || modes.none) === modes.none
        ? null
        : this.type === "time"
        ? moment(
            this.value || "00:00",
            dateTimeFormats.outputTimeFormat
          ).format(this.format)
        : this.value
        ? moment(this.value, this.outputFormat).format(this.format)
        : defaultDay(
            this.format,
            this.dataMode == modes.create ? deliveryWindows.endCutoffHours : 0
          );
    }

		if(this.division && this.division.toLowerCase() !== 'spg'){
			const loadDefaults = [
				{
					id: "txtLoadEarliestDate",
					date: "1950/01/01"
				},
				{
					id: "txtLoadEarliestTime",
					date: "1950/01/01"
				}
			]
			
			const foundDefault = loadDefaults.find(element => element.id === this.id);
			if(!this.isEditMode && foundDefault){
				this.options.minDate = moment(foundDefault.date);
			}
	
			this.$forceUpdate();
		}
  },

  watch: {
    selectedValue() {
      this.$emit("input", this.formatOutput());
    }
  },

  components: {
    datePicker
  },

  methods: {
    getFormat() {
      return this.type === "date"
        ? dateTimeFormats.dateOnlyFormat
        : this.type === "datetime"
        ? dateTimeFormats.datetimeFormat
        : this.type === "time"
        ? dateTimeFormats.timeOnlyFormat
        : dateTimeFormats.datetimeWithSecond;
    },

    getOutputFormat() {
      return this.type === "time"
        ? dateTimeFormats.outputTimeFormat
        : this.type === "date"
        ? dateTimeFormats.outputDateFormat
        : dateTimeFormats.outputDatetimeFormat;
    },

    onHide(e) {
      // buble up when hide the dropdown
      var oldValue = e.oldDate ? e.oldDate.format(this.outputFormat) : ""; // still keep the moment date format like mm/dd/yyyy h/mm a
      this.$emit("onHide", this.formatOutput(), oldValue);
    },

    onChange(e) {
      // buble up when the selected date changed: e.date/olddate has the old value
      var oldValue = e.oldDate ? e.oldDate.format(this.outputFormat) : ""; // still keep the moment date format like mm/dd/yyyy h/mm a
      this.$emit("onChange", this.formatOutput(), oldValue);
    },

    formatOutput() {
      // return formatted datetime string
      let formatted = this.selectedValue || "";
      if (formatted) {
        if (this.type === "time") {
          formatted = "01/01/1970 " + formatted; // just add day 0 for format
        }
        formatted = moment(new Date(formatted)).format(this.outputFormat);
      }
      return formatted;
    }
  },

  computed: {
    invalidInput() {
      return this.invalidState
    }
  }
}
</script>

<style lang="scss" scoped>
.invalid-input {
  border: 0.5px solid #c22032;
  border-radius: 5px;
}
</style>
